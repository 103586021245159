import { Button, Typography, Alert, Box } from '@mui/material'
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import TextFieldComponent from 'components/control/TextFieldComponent'
import { FormContainer } from 'react-hook-form-mui'
import { useLingui } from '@lingui/react/macro'
import React, { useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import IStudyData, { StudyImportSchema } from 'types/IStudyData'
import paths from 'utils/paths'
import { useSnackbar } from 'notistack'
import { MutatorCallback } from 'swr'
import { SelectComponentItem } from 'components/control/Select'
import useTemplates from 'hooks/useTemplates'
import SelectComponent from 'components/control/SelectComponent'
import StudyListDataService from 'data-services/StudyListDataService'
import FileUploadDialog from 'components/dialog/FileUploadDialog'
import StudiesService from 'data-services/StudiesService'
import StationDataService from 'data-services/StationDataService'
import IStationData from 'types/IStationData'
import ToolDataService from 'data-services/ToolDataService'
import StudyRepository from 'repositories/StudyRepository'

type StudyFormProps = {
  projectId: string
  study: IStudyData | null
  isLoading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  onModalClose: () => void
  mutator: MutatorCallback
  setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function StudyForm(props: StudyFormProps) {
  const { projectId, study, isLoading, setLoading, onModalClose, mutator, setSubmitting } = props
  const { t } = useLingui()
  const navigate: NavigateFunction = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [studyFromFile, setStudyFromFile] = useState<IStudyData>(null)

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IStudyData>({
    defaultValues: study ? useMemo(() => study, [study]) : undefined,
  })
  const studyTitleWatch = useWatch({ control, name: 'title' })
  const { data: templates } = useTemplates(paths.backend.study.studiesTemplates)
  const studyTemplateWatch = useWatch({ control, name: 'is_template' })

  const templateData: SelectComponentItem[] = useMemo(
    () =>
      templates
        ?.filter((td) => td.language_code === t`language-code`)
        ?.map((td) => ({ id: td.id.toString(), name: td.title, note: td.note }))
        ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) ?? [],
    [templates],
  )

  useEffect(() => {
    if (!study) {
      const template = templateData.find(
        (templateObject) => templateObject.id === (studyTemplateWatch as never),
      )
      setValue('title', template?.name)
      setValue('note', template?.note)
    }
  }, [studyTemplateWatch])

  function createNewStation(studyId: string, row: number, column: number): IStationData {
    return {
      id: '',
      title: '',
      subtitle: '',
      subject: '',
      note: '',
      tools: [],
      direction: 'row',
      is_pinned: false,
      is_template: false,
      language_code: '',
      row,
      column,
      row_count: 0,
      column_count: 0,
      study: studyId,
      pin_order: 0,
    }
  }

  function createNewTool(stationId: string, toolType: string, row: number, column: number) {
    return {
      id: '',
      title: '',
      subtitle: '',
      note: '',
      terms: [],
      station: stationId,
      source_language: '',
      destination_language: '',
      tool_type: toolType,
      is_template: false,
      is_processing: false,
      ai_provider: '',
      ai_model: '',
      ai_personality: '',
      ai_personality_title: '',
      ai_journal: '',
      is_prompt_to_card: false,
      is_prompt_to_journal: false,
      ai_temperature: 0,
      ai_context_lists: [],
      is_ai_personality: false,
      language_code: '',
      row,
      column,
      prompt: '',
    }
  }

  const handleImportFromTemplate = (templateId: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      if (projectId) {
        setSubmitting(true)
        StudyListDataService.importStudyFromTemplateStudyList(projectId, {
          template_id: templateId,
          title: getValues('title'),
          note: getValues('note'),
        })
          .then(() => {
            mutator().then(() => {
              onModalClose()
              resolve(true)
            })
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }
    })

  const handleImportFromFile = (): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      if (projectId && studyFromFile) {
        setSubmitting(true)
        const studyImportOverwrite = {
          ...studyFromFile,
          title: getValues('title'),
          note: getValues('note'),
        }
        StudiesService.importStudies(projectId, studyImportOverwrite)
          .then(() => {
            mutator().then(() => {
              onModalClose()
              resolve(true)
            })
          })
          .finally(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }
    })

  const handleStudyFormSubmit = (data: IStudyData): Promise<boolean> | boolean => {
    setLoading(true)
    data.study_list = projectId

    if (studyFromFile) {
      return handleImportFromFile()
    }

    if (studyTemplateWatch) {
      return handleImportFromTemplate(studyTemplateWatch as never)
    }

    if (templateData) {
      if (!getValues('note')) {
        setValue('note', templateData[0]?.note)
      }
      return handleImportFromTemplate(templateData[0]?.id as string)
    }

    let responsePromise: Promise<AxiosResponse<IStudyData>> | Promise<IStudyData>

    if (study) responsePromise = StudyRepository.updateStudy(data.id, data)
    else {
      responsePromise = StudyRepository.createStudy(data)
    }

    responsePromise
      .then((data) => {
        mutator().then(() => {
          onModalClose()
          if (study) {
            enqueueSnackbar(t`Study "${data.title}" successfully updated`, {
              variant: 'success',
            })
          } else {
            StationDataService.addStation(createNewStation(data.id, 0, 0)).then(
              ({ data: stationResponse }) => {
                ToolDataService.addTool(createNewTool(stationResponse.id, '', 0, 0)).then(() =>
                  navigate(paths.frontend.study(data.id)),
                )
              },
            )
          }
        })
      })
      .catch((err) => {
        if (err.response.status !== 404 && err.response.data) {
          Object.keys(err.response.data).forEach((key) => {
            setError(key as keyof IStudyData, {
              message: err.response.data[key],
            })
          })
        } else {
          setError('root.serverError', {
            message: t`Unexpected result, please try again.`,
          })
        }
      })
      .finally(() => setLoading(false))

    return true
  }

  const handleFileValidationClick = (
    json: string,
    setErrorList: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    if (projectId) {
      StudyImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
        .then((value: unknown) => {
          const newStudy: IStudyData = value as IStudyData
          setImportDialogOpen(false)
          setStudyFromFile(newStudy)
          setValue('title', newStudy?.title)
          setValue('note', newStudy?.note)
        })
        .catch(({ errors }) => {
          setErrorList([t`Invalid file format`, ...errors])
        })
    }
  }

  const handleCloseImportDialog = (): void => {
    setImportDialogOpen(false)
  }

  const handleImportFromFileClick = () => {
    setImportDialogOpen(true)
  }

  const handleImportFromFileCancelClick = () => {
    setStudyFromFile(null)
  }

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography color="textPrimary" variant="h1" fontWeight={400}>
        {study ? t`Edit study` : t`Add new study`}
      </Typography>

      <FormContainer
        onSuccess={
          handleSubmit((e) => handleStudyFormSubmit(e)) as unknown as SubmitHandler<FieldValues>
        }
      >
        {errors.root?.serverError && (
          <Box margin="0 0 24px 0">
            <Alert severity="error">{errors.root?.serverError.message}</Alert>
          </Box>
        )}

        {studyFromFile && (
          <Alert
            severity="info"
            sx={{ margin: '0 0 24px 0', width: '100%' }}
            className="MuiAlert-fullwidth"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Typography>{t`You are importing from a file`}</Typography>
              <Button
                variant="text"
                type="button"
                disabled={isLoading}
                sx={{ width: 'auto' }}
                onClick={handleImportFromFileCancelClick}
              >
                {t`Cancel`}
              </Button>
            </Box>
          </Alert>
        )}

        <Box display="flex" flexDirection="column" gap="24px">
          {!study && !studyFromFile && (
            <Box>
              <SelectComponent
                control={control as never}
                name="is_template"
                label={t`Template`}
                errors={errors as never}
                options={templateData ? (templateData as []) : []}
                sx={{ background: 'white', width: '100%' }}
              />
            </Box>
          )}

          <Box>
            <TextFieldComponent
              control={control as never}
              name="title"
              type="text"
              label={t`Study name`}
              errors={errors}
              requiredMsg={t`Study title is required`}
              autoFocus
              sx={{ background: 'white', width: '100%' }}
            />
          </Box>

          <Box>
            <TextFieldComponent
              control={control as never}
              name="note"
              type="text"
              label={t`Study description`}
              errors={errors}
              sx={{ background: 'white', width: '100%' }}
              multiline
            />
          </Box>

          <Box display="flex" margin="16px 0" gap="8px">
            <Box display="flex">
              <Button
                variant="text"
                type="button"
                disabled={isLoading}
                sx={{ width: 'auto' }}
                onClick={() => onModalClose()}
              >
                {t`Cancel`}
              </Button>
            </Box>

            <Box display="flex" gap="8px" justifyContent="flex-end" flexGrow="1">
              {!study && (
                <Button
                  variant="containedWhite"
                  type="button"
                  disabled={isLoading}
                  sx={{ width: 'auto' }}
                  onClick={handleImportFromFileClick}
                >
                  {t`From File`}
                </Button>
              )}

              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !studyTitleWatch}
                sx={{ width: 'auto' }}
              >
                {study ? t`Update` : t`Add study`}
              </Button>
            </Box>
          </Box>
        </Box>
      </FormContainer>

      <FileUploadDialog
        description={t`Click or drag to import study`}
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
        onUpload={handleFileValidationClick}
        title={t`New study from file`}
      />
    </Box>
  )
}
