import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { AddOutlined } from '@mui/icons-material'

type AddImportButtonProps = {
  handleAddClick: () => void
  addButtonText: string
}

export default function AddImportButton(props: AddImportButtonProps) {
  const { handleAddClick, addButtonText } = props

  return (
    <Box>
      <Box display="flex" gap="24px" justifyContent="flex-end">
        <Box>
          <Button
            onClick={handleAddClick}
            variant="containedDarkBlue"
            type="button"
            sx={{ width: 'auto', padding: '8px 24px' }}
            startIcon={<AddOutlined fontSize="large" />}
          >
            <Typography fontWeight={500} noWrap>
              {addButtonText}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
