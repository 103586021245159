import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  ListItemText,
  Typography,
  SelectProps,
} from '@mui/material'
import { Control, Controller, DeepRequired, FieldErrorsImpl } from 'react-hook-form'
import React from 'react'
import { useLingui } from '@lingui/react/macro'

type SelectComponentProps = SelectProps & {
  control: Control<unknown>
  label: string
  requiredMsg?: string
  name: string
  errors?: FieldErrorsImpl<DeepRequired<unknown>>
  options: []
  InputProps?: {}
  helperText?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  noOptionElement?: React.ReactNode
  includeBlankOption?: boolean
}

export default function SelectComponent(props: SelectComponentProps) {
  const {
    control,
    label,
    requiredMsg,
    name,
    errors,
    options,
    helperText,
    sx,
    disabled,
    noOptionElement,
    includeBlankOption,
    ...rest
  } = props

  const { t } = useLingui()

  const showNoOptionElement = () => noOptionElement && options && options.length === 0

  const getValue = (value: string | { id: string }) => {
    if (!value) {
      return ''
    }
    if (typeof value === 'object' && value.id) {
      return value.id
    }
    return value
  }

  return (
    <Controller
      control={control}
      name={name as never}
      rules={{
        required: requiredMsg ?? false,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <FormControl sx={sx}>
          <InputLabel id={name} disabled={disabled ?? false} error={!!errors[name]}>
            {requiredMsg && label ? `${label}*` : label}
          </InputLabel>
          <Select
            labelId={name}
            label={requiredMsg && label ? `${label} *` : label && label}
            value={getValue(value)}
            error={!!errors[name]}
            onChange={(e) => {
              onChange(e)
              if (props.onChange) props.onChange(e as never)
            }}
            onBlur={onBlur}
            inputRef={ref}
            disabled={disabled}
            {...rest}
          >
            {options.map((option: { id: string; name: string }) => (
              <MenuItem key={option.id} value={option.id}>
                <Typography variant="subtitle2" color="textSecondary" fontWeight={400}>
                  <ListItemText disableTypography primary={option.name} />
                </Typography>
              </MenuItem>
            ))}

            {includeBlankOption && (
              <MenuItem value="">
                <Typography variant="subtitle2" fontWeight={400}>
                  <ListItemText disableTypography primary={t`None`} />
                </Typography>
              </MenuItem>
            )}
          </Select>
          {(errors[name] || noOptionElement) && (
            <FormHelperText error={!!errors[name]}>
              {!showNoOptionElement() && errors[name]
                ? (errors[name] as { message: string })?.message
                : helperText}
              {showNoOptionElement() && noOptionElement}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
