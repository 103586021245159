import { Control, Controller, FieldErrors, get, Path } from 'react-hook-form'
import { Box, FormControl, TextField, TextFieldProps } from '@mui/material'

export type FormTextFieldProps<T> = Omit<TextFieldProps, 'name'> & {
  name: Path<T>
  control: Control<T>
  helperText?: string
  errors?: FieldErrors<T>
}

const FormTextFieldBlur = <T extends object>({
  placeholder,
  name,
  control,
  helperText,
  errors,
  ...others
}: FormTextFieldProps<T>) => (
  <Box display="flex" sx={{ width: '100%' }}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ width: '100%' }}>
          <TextField
            {...others}
            placeholder={placeholder}
            onChange={onChange}
            value={value ?? ''}
            variant="standard"
            error={!!get(errors, name)}
            helperText={get(errors, name) ? get(errors, name)?.message : helperText}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
              }
            }}
          />
        </FormControl>
      )}
    />
  </Box>
)

export default FormTextFieldBlur
