import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import { useLingui } from '@lingui/react/macro'

import { Box, Button, SvgIcon, Typography } from '@mui/material'
import {
  PostAdd,
  DeleteOutlined,
  GridViewOutlined,
  SpaceDashboardOutlined,
  TableRowsOutlined,
  ViewWeekOutlined,
  Downloading,
  Terminal,
  InputOutlined,
  ContentCopyOutlined,
  CheckBoxOutlined,
  SvgIconComponent,
} from '@mui/icons-material'

import { KeyedMutator } from 'swr'

import IStationData, { StationImportSchema } from 'types/IStationData'
import StationDataService from 'data-services/StationDataService'

import StudyDataService from 'data-services/StudyDataService'

import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import FileUploadDialog from 'components/dialog/FileUploadDialog'
import TemplateDialog from 'components/control/TemplateDialog'

import { stationMutate } from 'hooks/studyMutations'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard, IClipboardItemState } from 'contexts/ClipboardContext'
import { useErrorContext } from 'contexts/ErrorContext'

import orderingHelper from 'utils/orderingHelper'
import paths from 'utils/paths'

import { colors } from 'shared/theme'
import DoubleArrowRightIcon from 'assets/icons/double-arrow-right.svg?react'
import PushRightIcon from 'assets/icons/push-right.svg?react'
import PushDownIcon from 'assets/icons/push-down.svg?react'
import EmptyStationIcon from 'assets/icons/empty-station.svg?react'

import IStudyData, {
  IPasteDataInterface,
  IStudyGridItemData,
  IStudyImportData,
} from 'types/IStudyData'

type ActionMenuStationProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
}

export default function ActionMenuStation({
  study,
  mutateStudy,
  clipboard,
}: ActionMenuStationProps) {
  const { t } = useLingui()
  const {
    actionMenuContext,
    setActionMenuContext,
    isContextMenuLoading,
    setContextMenuLoading,
    resetActionMenuContext,
  } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()
  const actionButtonDeleteRef = useRef<HTMLButtonElement>(null)
  const [actionButtonDeleteOpen, setActionButtonDeleteOpen] = useState(false)
  const actionButtonAddStationRef = useRef<HTMLButtonElement>(null)
  const [actionButtonAddStationOpen, setActionButtonAddStationOpen] = useState(false)
  const actionButtonPushStationRef = useRef<HTMLButtonElement>(null)
  const [actionButtonPushStationOpen, setActionButtonPushStationOpen] = useState(false)
  const actionButtonCopyMoveRef = useRef<HTMLButtonElement>(null)
  const [actionButtonCopyMoveOpen, setActionButtonCopyMoveOpen] = useState(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)

  function createNewStation(row: number, column: number): IStationData {
    return {
      id: '',
      title: '',
      subtitle: '',
      subject: '',
      note: '',
      tools: [],
      direction: 'row',
      is_pinned: false,
      is_template: false,
      language_code: '',
      row,
      column,
      row_count: 0,
      column_count: 0,
      study: study.id,
      pin_order: 0,
    }
  }

  const addStation = (newStation: IStationData): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      StationDataService.addStation(newStation)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const removeStudyGridItem = (data: IStudyGridItemData): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      StudyDataService.removeStudyGridItem(study.id, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const deleteStation = (stationId: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      StationDataService.deleteStation(stationId)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const pushStation = (data: IStudyGridItemData): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      StationDataService.pushStation(actionMenuContext.station.id, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const deleteStationTools = (stationId: string): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      StationDataService.deleteStationTools(stationId)
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          resolve(stationResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const importStationFromTemplate = (templateId: string): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.stationGridItem)
      StudyDataService.importStationFromTemplateStudy(study.id, {
        row,
        column,
        template_id: templateId,
      })
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          const newStation = studyResponse.stations.find(
            (station) => station.row === row && station.column === column,
          )
          if (newStation) {
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              station: newStation,
            }))
          }
          setActionButtonAddStationOpen(false)
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const importStationFromFile = (data: IStudyImportData): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      StudyDataService.importStationFromFileStudy(study.id, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const pasteStations = (data: IPasteDataInterface): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      StationDataService.pasteStations(study.id, data)
        .then(({ data: studyResponse }) => {
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleAddStationClick = () => {
    // Check if selected station grid item
    if (actionMenuContext.stationGridItem) {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.stationGridItem)
      const station = orderingHelper.findStationByRowColumn(study.stations, row, column)

      if (!station) {
        const newStation = createNewStation(row, column)
        setContextMenuLoading(true)
        addStation(newStation).then((newStationResponse) => {
          mutateStudy()
            .then(() => {
              setActionMenuContext((prevActionMenuContext) => ({
                ...prevActionMenuContext,
                station: newStationResponse,
              }))
            })
            .finally(() => setContextMenuLoading(false))
        })
      }
    }

    setActionButtonAddStationOpen(false)
  }

  const handleRemoveStudyGridItem = (target: 'row' | 'column') => {
    const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.stationGridItem)
    setContextMenuLoading(true)
    removeStudyGridItem({
      target,
      selected_column: column,
      selected_row: row,
    })
      .then((studyResponse) => {
        const newStation = orderingHelper.findStationByRowColumn(
          studyResponse.stations,
          row,
          column,
        )
        if (newStation)
          setActionMenuContext((prevActionMenuContext) => ({
            ...prevActionMenuContext,
            station: newStation,
          }))
      })
      .finally(() => setContextMenuLoading(false))
    setActionButtonDeleteOpen(false)
  }

  const handleDeleteStation = () => {
    if (actionMenuContext.station) {
      setContextMenuLoading(true)
      deleteStation(actionMenuContext.station.id).then(() => {
        mutateStudy()
          .then(() => {
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              station: null,
            }))
          })
          .finally(() => setContextMenuLoading(false))
      })
    }
    setActionButtonDeleteOpen(false)
  }

  const handlePushStation = (direction: 'bottom' | 'right') => {
    setContextMenuLoading(true)
    pushStation({
      direction,
    })
      .then((studyResponse) => {
        setActionMenuContext((prevActionMenuContext) => {
          const newStation = studyResponse.stations.find(
            (station) => actionMenuContext.station.id === station.id,
          )
          return {
            ...prevActionMenuContext,
            stationGridItem: `station-grid-item-${newStation.row}-${newStation.column}`,
          }
        })
      })
      .finally(() => setContextMenuLoading(false))
    setActionButtonPushStationOpen(false)
  }

  const handleDeleteAllTools = () => {
    if (actionMenuContext.station) {
      setContextMenuLoading(true)
      deleteStationTools(actionMenuContext.station.id).finally(() => setContextMenuLoading(false))
    }
    setActionButtonDeleteOpen(false)
  }

  const handleOpenTemplateDialog = (): void => {
    setActionButtonAddStationOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleImportStationFromFileDialog = (): void => {
    setActionButtonAddStationOpen(false)
    setImportDialogOpen(true)
  }

  const handleImportStationFromFile = useCallback(
    (json: string, setErrorList: Dispatch<SetStateAction<string[]>>) => {
      if (actionMenuContext && actionMenuContext.stationGridItem) {
        StationImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
          .then((value: unknown) => {
            const { row, column } = orderingHelper.getRowColumnByKey(
              actionMenuContext.stationGridItem,
            )
            const templateStation: IStationData = value as IStationData
            importStationFromFile({
              row,
              column,
              station: templateStation,
            }).then(() => {
              setImportDialogOpen(false)
            })
          })
          .catch(({ errors }) => {
            console.log(errors)
            setErrorList([t`Invalid file format`, ...errors])
          })
      }
    },
    [],
  )

  const handlePasteFromClipboardSelection = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)

    const stationStates: IClipboardItemState<IStationData>[] =
      clipboard.stations.getSelectedItemStates()

    if (!actionMenuContext || !actionMenuContext.stationGridItem || stationStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.stationGridItem)
    const stations = clipboard.stations.getSelectedItemStates()
    const stationPasteData: IPasteDataInterface = {
      row,
      column,
      actions: stations.map((station) => ({
        action,
        station: station.data.id,
      })),
    }

    pasteStations(stationPasteData)
      .then((studyResponse: IStudyData) => {
        mutateStudy(studyResponse, false).then(() => {
          clipboard.stations.removeSelectedItemStates()
          resetActionMenuContext()
        })
      })
      .finally(() => setContextMenuLoading(false))
  }

  return (
    <Box
      display="flex"
      gap={{ xs: '8px', mobile: '32px' }}
      flexGrow={actionMenuContext.toolGridItem ? '0' : '1'}
      alignItems="center"
      flexWrap={{ xs: 'wrap', mobile: 'nowrap' }}
    >
      {actionMenuContext && !actionMenuContext.station && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonAddStationRef}
          actionMenuButtonAnchorOpen={actionButtonAddStationOpen}
          setActionMenuButtonAnchorOpen={setActionButtonAddStationOpen}
          handleActionMenuButtonClick={() =>
            setActionButtonAddStationOpen(!actionButtonAddStationOpen)
          }
          StartIcon={PostAdd}
          title={t`New Station`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              <Button
                onClick={() => handleAddStationClick()}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SvgIcon sx={{ color: colors.black.A500 }}>
                    <EmptyStationIcon />
                  </SvgIcon>
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Empty Station`}
                </Typography>
              </Button>

              <Button
                onClick={handleImportStationFromFileDialog}
                variant="text"
                type="button"
                disableRipple
                startIcon={<Downloading sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Import From File`}
                </Typography>
              </Button>

              <Button
                onClick={handleOpenTemplateDialog}
                variant="text"
                type="button"
                disableRipple
                startIcon={<Terminal sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Import From Template`}
                </Typography>
              </Button>
            </Box>
          }
        />
      )}

      {actionMenuContext && actionMenuContext.station && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonPushStationRef}
          actionMenuButtonAnchorOpen={actionButtonPushStationOpen}
          setActionMenuButtonAnchorOpen={setActionButtonPushStationOpen}
          handleActionMenuButtonClick={() =>
            setActionButtonPushStationOpen(!actionButtonPushStationOpen)
          }
          StartIcon={DoubleArrowRightIcon as unknown as SvgIconComponent}
          title={t`Push Station`}
          minWidth={{ xs: '160px' }}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 8px" flexDirection="column">
              <Button
                onClick={() => handlePushStation('right')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SvgIcon sx={{ color: colors.black.A500 }}>
                    <PushRightIcon />
                  </SvgIcon>
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Push Right`}
                </Typography>
              </Button>

              <Button
                onClick={() => handlePushStation('bottom')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SvgIcon sx={{ color: colors.black.A500 }}>
                    <PushDownIcon />
                  </SvgIcon>
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Push Down`}
                </Typography>
              </Button>
            </Box>
          }
        />
      )}

      {clipboard.stations.getSelectedItemStates().length > 0 && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonCopyMoveRef}
          actionMenuButtonAnchorOpen={actionButtonCopyMoveOpen}
          setActionMenuButtonAnchorOpen={setActionButtonCopyMoveOpen}
          handleActionMenuButtonClick={() => setActionButtonCopyMoveOpen(!actionButtonCopyMoveOpen)}
          StartIcon={CheckBoxOutlined}
          title={`${t`Selected Stations`} (${clipboard.stations.getSelectedItemStates().length})`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelection('cut')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <InputOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.station ? t`Cut and Paste` : t`Cut and Paste`}
                  </Typography>
                </Button>
              </Box>

              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelection('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.station ? t`Copy and Paste` : t`Copy and Paste`}
                  </Typography>
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      <ActionMenuButton
        actionMenuButtonRef={actionButtonDeleteRef}
        actionMenuButtonAnchorOpen={actionButtonDeleteOpen}
        setActionMenuButtonAnchorOpen={setActionButtonDeleteOpen}
        handleActionMenuButtonClick={() => setActionButtonDeleteOpen(!actionButtonDeleteOpen)}
        StartIcon={DeleteOutlined}
        title={t`Delete`}
        isDanger
        isButtonDisabled={isContextMenuLoading}
        actionMenuItems={
          <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
            {actionMenuContext && actionMenuContext.station && (
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={handleDeleteAllTools}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <GridViewOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon-danger"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    color="textPrimary"
                    textAlign="left"
                    margin="0 0 0 8px"
                    className="MuiTypography-danger"
                  >
                    {t`All Tools in Station`}
                  </Typography>
                </Button>
                {actionMenuContext?.station?.is_pinned ? (
                  ''
                ) : (
                  <Button
                    onClick={() => handleDeleteStation()}
                    variant="text"
                    type="button"
                    disableRipple
                    startIcon={
                      <SpaceDashboardOutlined
                        sx={{ color: colors.black.A500 }}
                        className="MuiButton-startIcon-danger"
                      />
                    }
                    sx={{ width: '100%', padding: '8px 24px' }}
                  >
                    <Typography
                      color="textPrimary"
                      textAlign="left"
                      margin="0 0 0 8px"
                      className="MuiTypography-danger"
                    >
                      {t`Station`}
                    </Typography>
                  </Button>
                )}
              </Box>
            )}
            {actionMenuContext?.station?.is_pinned ? (
              ''
            ) : (
              <Button
                onClick={() => handleRemoveStudyGridItem('column')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <ViewWeekOutlined
                    sx={{ color: colors.black.A500 }}
                    className="MuiButton-startIcon-danger"
                  />
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography
                  color="textPrimary"
                  textAlign="left"
                  margin="0 0 0 8px"
                  className="MuiTypography-danger"
                >
                  {t`Station Column`}
                </Typography>
              </Button>
            )}
            {actionMenuContext?.station?.is_pinned ? (
              ''
            ) : (
              <Button
                onClick={() => handleRemoveStudyGridItem('row')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <TableRowsOutlined
                    sx={{ color: colors.black.A500 }}
                    className="MuiButton-startIcon-danger"
                  />
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography
                  color="textPrimary"
                  textAlign="left"
                  margin="0 0 0 8px"
                  className="MuiTypography-danger"
                >
                  {t`Station Row`}
                </Typography>
              </Button>
            )}
          </Box>
        }
      />

      <FileUploadDialog
        description={t`Click or drag to import station`}
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        onUpload={handleImportStationFromFile}
        title={t`New station from file`}
      />

      {templateDialogOpen && (
        <TemplateDialog
          description={t`Add a station (including its tools and terms) from a template`}
          templatesEndpoint={paths.backend.study.station.stationsTemplates}
          open={templateDialogOpen}
          onClose={() => setTemplateDialogOpen(false)}
          onImportFromTemplate={importStationFromTemplate}
          title={t`New station from template`}
        />
      )}
    </Box>
  )
}
