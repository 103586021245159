import React, { createContext, useState, useContext, useMemo, useEffect, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

interface DrawerComponentContextType {
  drawerOpen: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface DrawerComponentContextProviderProps {
  children: ReactNode
}

const DrawerComponentContext = createContext<DrawerComponentContextType | undefined>(undefined)

export const useDrawerComponentContext = () => {
  const context = useContext(DrawerComponentContext)
  if (!context) {
    throw new Error(
      'useContextStudyEventStates must be used within a DrawerComponentContextProvider',
    )
  }
  return context
}

export function DrawerComponentContextProvider({ children }: DrawerComponentContextProviderProps) {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    setDrawerOpen(mdUp)
  }, [mdUp])

  const value = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen,
    }),
    [drawerOpen],
  )

  return <DrawerComponentContext.Provider value={value}>{children}</DrawerComponentContext.Provider>
}
