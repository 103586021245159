import { t } from '@lingui/core/macro'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    defined: ({ path }) => t`${path} must be defined`,
    required: ({ path }) => t`${path} is required`,
  },
  string: {
    email: ({ path }) => t`${path} must be a valid email`,
    matches: ({ path, regex }) => t`${path} must have a valid value: ${regex}`,
    max: ({ path, max }) => t`${path} must be at most ${max} characters`,
    min: ({ path, min }) => t`${path} must be at least ${min} characters`,
    url: ({ path }) => t`${path} must be a valid URL`,
  },
})

export default yup
