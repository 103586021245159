import { i18n } from '@lingui/core'

export const locales = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
}

export const defaultLocale = localStorage.getItem('language') || 'en'

export async function dynamicActivate(locale: string) {
  const { messages } = await import(`../locales/${locale}/messages.po`)

  i18n.loadAndActivate({ locale, messages })
}

export function setInitialLanguage(language: string): void {
  localStorage.setItem('language', language)
}
