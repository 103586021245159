import { useCallback } from 'react'
import { useLingui } from '@lingui/react'

import { Box, FormControl, MenuItem, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { dynamicActivate, locales, setInitialLanguage } from 'utils/i18n'

function LanguageSelector() {
  const { i18n } = useLingui()

  const handleSelectChange = useCallback((e: SelectChangeEvent): void => {
    // Set initial language for the next time the app is loaded from scratch
    setInitialLanguage(e.target.value)
    dynamicActivate(e.target.value)
  }, [])

  return (
    <Box sx={{ ml: 2 }}>
      <FormControl variant="standard">
        <Select
          disableUnderline
          onChange={handleSelectChange}
          value={i18n.locale}
          variant="standard"
          className="MuiSelect-text"
        >
          {Object.values(locales).map((locale, index) => (
            <MenuItem sx={{ py: '4px' }} value={Object.keys(locales)[index]} key={locale}>
              <Typography>{locale}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default LanguageSelector
