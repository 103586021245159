import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Alert,
  Box,
  Button,
  Grid2,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import { useLingui } from '@lingui/react/macro'
import AccountDataService from '../../data-services/AccountDataService'
import FormTextField from '../../components/control/FormTextField'

type FormLoginFields = {
  email: string
  password: string
}

export default function Login() {
  const { t } = useLingui()

  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [submitWarning, setSubmitWarning] = useState('')
  const [submitError, setSubmitError] = useState('')

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .label(t`The email address`)
      .required()
      .email(),
    password: yup
      .string()
      .label(t`The password`)
      .required(),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormLoginFields>({
    resolver: yupResolver(formSchema),
  })

  const handleShowPasswordClick = (): void => {
    setShowPassword(!showPassword)
  }

  const handlePasswordMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const handleFormSubmit = (data: FormLoginFields): void => {
    setSubmitWarning('')
    setSubmitError('')

    AccountDataService.login(data.email.toLowerCase(), data.password)
      .then(() => {
        navigate('/')
      })
      .catch((err) => {
        console.log('ERROR: An error occurred during sign in', err, err.response)
        if (err.response.status === 401 && err.response.data === 'no_active_account') {
          setSubmitWarning(t`No active account found with the given credentials.`)
        } else if (
          err.response.status === 401 &&
          err.response.data === 'email_verification_failed'
        ) {
          setSubmitWarning(
            t`Your account is not verified. We have sent an email to you for verification. Follow the link provided to finalize the signup process.`,
          )
        } else {
          setSubmitError(t`An error occurred during sign in, please try again.`)
        }
      })
  }

  return (
    <>
      <Typography component="h1" variant="h6">
        {t`Login`}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(handleFormSubmit)}
        sx={{ mt: 1, width: '100%' }}
      >
        <FormTextField
          autoFocus
          errorText={errors.email?.message}
          label={t`Email Address`}
          margin="normal"
          registerReturn={register('email')}
        />
        <FormTextField
          errorText={errors.password?.message}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleShowPasswordClick()}
                    onMouseDown={handlePasswordMouseDown}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          label={t`Password`}
          margin="normal"
          registerReturn={register('password')}
          type={showPassword ? 'text' : 'password'}
        />
        {submitWarning !== '' && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            {submitWarning}
          </Alert>
        )}
        {submitError !== '' && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {submitError}
          </Alert>
        )}
        <Button color="primary" fullWidth sx={{ mb: 2, mt: 3 }} type="submit" variant="contained">
          {t`Sign In`}
        </Button>
        <Grid2 container>
          <Grid2 size="grow">
            <Link href="/auth/forgot-password/" variant="body2">
              {t`Forgot password?`}
            </Link>
          </Grid2>
          <Grid2>
            <Link href="/auth/signup/" variant="body2">
              {t`Don't have an account? Sign Up`}
            </Link>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
