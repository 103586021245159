import { Box, Typography } from '@mui/material'

import { useLingui } from '@lingui/react/macro'
import ForgotPasswordForm from '../account/ForgotPasswordForm'

export default function ForgotPassword() {
  const { t } = useLingui()

  return (
    <>
      <Typography component="h1" variant="h6">
        {t`Forgot password?`}
      </Typography>
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        {t`Enter your email address below, and we'll send you an email allowing you to reset it.`}
      </Box>
      <ForgotPasswordForm onFormSubmitted={() => {}} submitButtonText={t`Reset password`} />
    </>
  )
}
