import { useLingui } from '@lingui/react/macro'
import { Box } from '@mui/material'

import { useFormContext } from 'react-hook-form'

import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import FloatingActionMenuStation from 'components/floating-action-menu/FloatingActionMenuStation'
import { FormStudyData } from 'components/form/StudyDataFormBody'

import { IClipboard } from 'contexts/ClipboardContext'

import theme, { colors } from 'shared/theme'

import IStationData from 'types/IStationData'

type StationHeaderProps = {
  station: IStationData
  clipboard: IClipboard
}

function StationHeader({ station, clipboard }: StationHeaderProps) {
  const { t } = useLingui()
  const { control } = useFormContext<FormStudyData>()

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }

    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const config = {
      title: {
        ...theme.typography.h1,
        fontWeight: 400,
        color: colors.blue.A800,
      },
      note: {
        ...theme.typography.body1,
        fontWeight: 400,
        color: colors.gray.A600,
      },
    }

    const defaultStyle = {}
    const chosenStyle = config[name] || defaultStyle

    return {
      ...generalProps,
      style: {
        ...generalStyles,
        ...chosenStyle,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...chosenStyle,
        },
      },
    }
  }

  return (
    <div>
      <Box
        display="flex"
        width="100%"
        sx={{
          background: 'transparent',
        }}
      >
        <FormTextFieldBlur
          // @ts-ignore
          name={`stations[${station.id}].title`}
          placeholder={t`Station title`}
          slotProps={{
            input: getInputProps('title'),
          }}
          multiline
          control={control}
        />

        <FloatingActionMenuStation station={station} clipboard={clipboard} />
      </Box>

      <Box
        display="flex"
        width="100%"
        sx={{
          background: 'transparent',
          minHeight: '25px',
        }}
      >
        <FormTextFieldBlur
          // @ts-ignore
          name={`stations[${station.id}].note`}
          placeholder={t`Station description`}
          slotProps={{
            input: getInputProps('note'),
          }}
          multiline
          control={control}
        />
      </Box>
    </div>
  )
}

export default StationHeader
