import { TextField, FormControl, TextFieldProps } from '@mui/material'
import { Controller, DeepRequired, FieldErrorsImpl } from 'react-hook-form'

type TextFieldComponentProps = TextFieldProps & {
  control: never
  label: string
  requiredMsg?: string
  name: string
  errors?: FieldErrorsImpl<DeepRequired<unknown>>
  InputProps?: {}
  helperText?: string
  pattern?: RegExp
  min?: number
  max?: number
  className?: string
}
export default function TextFieldComponent(props: TextFieldComponentProps) {
  const {
    control,
    label,
    requiredMsg,
    name,
    errors,
    InputProps,
    helperText,
    pattern,
    min,
    max,
    sx,
    className,
    autoFocus,
    ...rest
  } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: requiredMsg ?? false,
        pattern,
        min,
        max,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <FormControl sx={sx} className={className}>
          <TextField
            {...rest}
            label={requiredMsg && label ? `${label} *` : label}
            error={errors && !!errors[name]}
            helperText={errors && errors[name] ? errors[name]?.message : helperText}
            slotProps={{
              input: InputProps,
            }}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value || ''}
            autoFocus={autoFocus}
          />
        </FormControl>
      )}
    />
  )
}
