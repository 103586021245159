import { memo, ReactNode, useCallback, useEffect, useRef } from 'react'

import { debounce, isEqual } from 'lodash'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { useErrorContext } from 'contexts/ErrorContext'

import studyToFormHeader from 'helpers/studyToFormHeader'

import StudyRepository from 'repositories/StudyRepository'

import IStudyData from 'types/IStudyData'

export type FormStudyDataHeader = {
  study: IStudyData
}

type StudyDataFormProps = {
  study: IStudyData
  studyForm: UseFormReturn<FormStudyDataHeader>
  resetTimer: () => void
  children: ReactNode
}

function StudyDataFormHeader({ study, studyForm, resetTimer, children }: StudyDataFormProps) {
  const { handleAsyncError } = useErrorContext()
  const previousValuesFromBackend = useRef({
    title: studyForm.getValues().study.title,
    note: studyForm.getValues().study.note,
  })

  StudyRepository.initialize(handleAsyncError)

  const getDirtyFieldValues = useCallback((studyForm: UseFormReturn<FormStudyDataHeader>) => {
    const { dirtyFields } = studyForm.formState
    const formData = studyForm.getValues()

    const dirtyValues = {}

    // The form only contains values for 'title' and 'note' for the header
    // See src/helpers/studyToFormHeader.ts
    const topLevelFields: (keyof IStudyData)[] = ['title', 'note']

    topLevelFields.forEach((field) => {
      if (dirtyFields.study?.[field]) {
        dirtyValues[field] = formData.study[field]
      }
    })

    return dirtyValues
  }, [])

  useEffect(() => {
    const values = studyToFormHeader(study)

    if (JSON.stringify(previousValuesFromBackend.current) !== JSON.stringify(values.study)) {
      const dirtyValues = getDirtyFieldValues(studyForm)

      const updatedStudy = {
        ...values.study,
        ...dirtyValues,
      }

      studyForm.reset({ study: updatedStudy })
      // @ts-ignore
      previousValuesFromBackend.current = updatedStudy
    }
  }, [study, studyForm])

  const handleSubmit = useCallback(
    (data: FormStudyDataHeader) => {
      const { dirtyFields } = studyForm.formState

      if (dirtyFields?.study) {
        if (isEqual(previousValuesFromBackend.current, data.study)) return

        void StudyRepository.updateStudy(study.id, data.study)
      }
    },
    [studyForm.formState.dirtyFields],
  )

  useEffect(() => {
    const debouncedSubmit = debounce((formValues) => handleSubmit(formValues), 500)
    const callback = (values: FormStudyDataHeader) => {
      resetTimer()
      debouncedSubmit(values)
    }

    const { unsubscribe } = studyForm.watch(callback)

    return () => unsubscribe()
  }, [studyForm.watch])

  return <FormProvider {...studyForm}>{children}</FormProvider>
}

export default memo(StudyDataFormHeader)
