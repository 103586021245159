import {
  createContext,
  useState,
  useContext,
  useMemo,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

interface studyStatesContextType {
  isDragging: boolean
  setIsDragging: Dispatch<SetStateAction<boolean>>
  pasteMenuCount: number
  setPasteMenuCount: Dispatch<SetStateAction<number>>
  isPinnedStationDrawerOpen: boolean
  setPinnedStationDrawerOpen: Dispatch<SetStateAction<boolean>>
}

interface StudyStatesContextProviderProps {
  children: ReactNode
}

const StudyStatesContext = createContext<studyStatesContextType | undefined>(undefined)

export const useStudyStatesContext = () => {
  const context = useContext(StudyStatesContext)

  if (!context) {
    throw new Error('useStudyStatesContext must be used within a StudyStatesContextProvider')
  }

  return context
}

export function StudyStatesContextProvider({ children }: StudyStatesContextProviderProps) {
  const [isDragging, setIsDragging] = useState(false)
  const [pasteMenuCount, setPasteMenuCount] = useState(0)
  const [isPinnedStationDrawerOpen, setPinnedStationDrawerOpen] = useState(false)

  const value = useMemo(
    () => ({
      isDragging,
      setIsDragging,

      pasteMenuCount,
      setPasteMenuCount,

      isPinnedStationDrawerOpen,
      setPinnedStationDrawerOpen,
    }),
    [isDragging, pasteMenuCount, isPinnedStationDrawerOpen],
  )

  return <StudyStatesContext.Provider value={value}>{children}</StudyStatesContext.Provider>
}
