/* eslint-disable camelcase */
import yup from 'utils/yupLocale'

export default interface ITermData {
  id: string
  term: string
  note: string
  order: number
  hyperlink: string
  tool: string
  is_error: boolean
  agent_model: string
  agent_tokens: string
  agent_meta: string
  image?: string
  file?: string
}

export interface ITermUploadStartData {
  file_name: string
  file_type: string
  is_image: boolean
}

export interface ITermUploadStartResponseData {
  fields: Object
  url: string
  id: string
}

export interface ITermUploadProgressResponseData {
  id: string
  file_id: string
}

export interface ITermMoveData {
  destination_tool: string
  destination_index: number
}

export const TermExportProps: string[] = [
  'term',
  'note',
  'hyperlink',
  'order',
  'is_error',
  'agent_model',
  'agent_tokens',
  'agent_meta',
]

export const TermImportSchema = yup.object().shape({
  term: yup.string().defined(),
  note: yup.string(),
  hyperlink: yup.string(),
  order: yup.number(),
  is_error: yup.boolean(),
  agent_model: yup.string(),
  agent_tokens: yup.string(),
  agent_meta: yup.string(),
})

export const serializeTerm = (term: ITermData): string => JSON.stringify(term, TermExportProps)
