import { useState, useEffect, useRef } from 'react'
import { KeyedMutator } from 'swr'

import IStudyData from 'types/IStudyData'

const useRefreshTimer = (mutate: KeyedMutator<IStudyData>) => {
  const timerRef = useRef(null)
  const [timeLeft, setTimeLeft] = useState(5)

  useEffect(() => {
    if (timeLeft === 0) {
      // Mutate to get changes from BE
      mutate()

      setTimeLeft(5)
    } else {
      timerRef.current = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
    }

    return () => clearTimeout(timerRef.current)
  }, [timeLeft])

  const resetTimer = () => {
    setTimeLeft(5)
  }

  return { timeLeft, resetTimer }
}

export default useRefreshTimer
