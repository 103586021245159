import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { forwardRef, ReactNode } from 'react'
import { SxProps } from '@mui/material/styles'
import { useLingui } from '@lingui/react/macro'
import { Close as CloseIcon } from '@mui/icons-material'

type GeneralDialogProps = {
  open: boolean
  scroll?: 'body' | 'paper'
  onModalClose: () => void
  sx: SxProps
  isLoading: boolean
  children: ReactNode
  dialogTitle?: string
  dialogTitleNode?: ReactNode
}

const DialogComponent = forwardRef((props: GeneralDialogProps, ref) => {
  const {
    dialogTitleNode,
    children,
    open,
    onModalClose,
    scroll,
    sx,
    dialogTitle,
    isLoading = false,
  } = props

  const { t } = useLingui()

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          onModalClose()
        }
      }}
      scroll={scroll ?? 'body'}
      sx={sx}
    >
      {dialogTitle && (
        <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
          <Box display="flex">
            <Box flexGrow={1}>{dialogTitle}</Box>
            <Tooltip title={t`Close`} disableInteractive>
              <IconButton onClick={onModalClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
      )}

      {dialogTitleNode && dialogTitleNode}

      {!dialogTitleNode && <Divider />}

      <DialogContent sx={{ padding: '24px 24px 0 24px' }} ref={ref}>
        {children}
      </DialogContent>
    </Dialog>
  )
})
DialogComponent.displayName = 'General dialog'
export default DialogComponent
