import React, { useState } from 'react'
import { Box, Collapse, IconButton, Paper, Typography } from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { RotationalExpandMoreIcon } from 'components/control/RotationalIcons'
import ITermData from 'types/ITermData'
import { useErrorContext } from 'contexts/ErrorContext'
import TermDataService from 'data-services/TermDataService'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'

export type TermProps = {
  term: ITermData
  mutateStudy: KeyedMutator<IStudyData>
}

function ErrorTerm({ term, mutateStudy }: TermProps) {
  const { t } = useLingui()
  const [expanded, setExpanded] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { handleAsyncError } = useErrorContext()
  const theme = useTheme()
  const termColors = theme.palette.term

  const handleDeleteClick = (): void => {
    TermDataService.deleteTerm(term.id)
      .then(() => {
        void mutateStudy()
      })
      .catch((err) => {
        handleAsyncError(err.message)
      })
  }

  return (
    <Paper
      key={term.id}
      sx={{ backgroundColor: termColors.error.background, m: 0, mb: 1, p: 1, pb: '8px' }}
      variant="outlined"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" justifyContent="center" alignItems={expanded ? 'stretch' : 'center'}>
        <Box sx={{ width: '100%' }}>
          <Box display="flex" margin={expanded ? '0 0 8px 0' : '0'} sx={{ width: '100%' }}>
            <Box sx={{ width: '50px' }}>
              <IconButton
                aria-label={t`Show more`}
                onClick={() => setExpanded(!expanded)}
                size="small"
                sx={{ mr: 1, px: 0 }}
              >
                <RotationalExpandMoreIcon degrees={expanded ? 0 : -90} />
              </IconButton>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5" whiteSpace="pre-wrap">
                {term.term}
              </Typography>
              {isHovered && (
                <IconButton onClick={handleDeleteClick}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Box>

          <Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  borderRadius: '4px',
                  padding: '8px',
                  background: termColors.errorDetails.background,
                }}
                position="relative"
              >
                <Typography variant="subtitle2" whiteSpace="pre-wrap">
                  {term.note ? term.note : t`No details provided`}
                </Typography>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default React.memo(ErrorTerm)
