import useSWR, { KeyedMutator } from 'swr'
import { AxiosResponse } from 'axios'
import IStudyListData from 'types/IStudyListData'
import paths from 'utils/paths'

type MutateFunction<Data> = KeyedMutator<Data>

function useStudyList(id?: string): {
  studyList: AxiosResponse<IStudyListData> | undefined
  mutateStudyList: MutateFunction<AxiosResponse<IStudyListData>>
  isLoading: boolean
} {
  const {
    data: studyList,
    mutate: mutateStudyList,
    isLoading,
  } = useSWR<AxiosResponse<IStudyListData>>(id ? paths.backend.studyList.studyLists(id) : null)

  return { studyList, mutateStudyList, isLoading }
}

export default useStudyList
