import useSWR from 'swr'
import { SWRResponse } from 'swr/_internal'
import axiosInstance from 'utils/axios'
import { useParams } from 'react-router-dom'
import IStudyData from 'types/IStudyData'

const rootPath = '/studies/studies'

function useStudy(studyId?: string | null): SWRResponse<IStudyData> {
  const { id = '0' } = useParams()

  return useSWR<IStudyData>(
    id ? `${rootPath}/${studyId ?? id}/` : null,
    (endpoint: string) => axiosInstance.get<IStudyData>(endpoint).then((res) => res?.data),
    {
      onError: (err) => {
        if (err.response.status === 404) {
          console.error('404 study not found')
        } else {
          console.error(err)
        }
      },
      shouldRetryOnError: false,
    },
  )
}

export default useStudy
