import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  CardActionArea,
  Avatar,
} from '@mui/material'
import { Dispatch, lazy, SetStateAction, Suspense, useRef, useState } from 'react'
import { useLingui } from '@lingui/react/macro'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { colors } from 'shared/theme'
import { useNavigate } from 'react-router-dom'
import paths from 'utils/paths'
import IStudyData from 'types/IStudyData'
import { MutatorCallback } from 'swr'

const StudyMoreActionMenu = lazy(() => import('components/study/StudyMoreActionMenu'))

type StudyCardProps = {
  studyListId: string
  study: IStudyData
  mutator: MutatorCallback
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
  setSelectedStudy: Dispatch<SetStateAction<IStudyData | null>>
}

const lineHeight = '1.5em'
const numberOfLines = 2
const maxHeight = `calc(${lineHeight} * ${numberOfLines})`

export default function StudyCard({
  studyListId,
  study,
  mutator,
  setStudyDialogOpen,
  setSelectedStudy,
}: StudyCardProps) {
  const { t } = useLingui()

  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  return (
    <>
      <Card elevation={1}>
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
          }}
        >
          <Box textOverflow="ellipsis" overflow="hidden" width="100%">
            <CardActionArea
              onClick={() => navigate(paths.frontend.study(study.id))}
              sx={{ padding: '16px 0 16px 16px' }}
            >
              <Box display="flex" gap="16px">
                <Avatar sx={{ bgcolor: colors.blue.A500 }}>
                  {study.title && study.title !== '' ? study.title[0] : 'N'}
                </Avatar>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexGrow={1}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  <Box textOverflow="ellipsis" overflow="hidden">
                    <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
                      {study.title && study.title !== '' ? study.title : t`New study`}
                    </Typography>
                    <Typography
                      component="p"
                      whiteSpace="pre-wrap"
                      sx={{
                        lineHeight,
                        maxHeight,
                        overflow: 'hidden',
                        display: '-webkit-box',
                        textOverflow: 'ellipsis',
                        margin: 0,
                        height: maxHeight,
                      }}
                    >
                      {study.note}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardActionArea>
          </Box>

          <Box padding="16px">
            <IconButton
              onClick={() => {
                setAnchorMenuOpen(!anchorMenuOpen)
              }}
              ref={anchorMenuRef}
              size="small"
              sx={{ color: colors.gray.A500, padding: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <Suspense fallback={null}>
        <StudyMoreActionMenu
          studyListId={studyListId}
          study={study}
          mutator={mutator}
          anchorMenuOpen={anchorMenuOpen}
          setAnchorMenuOpen={setAnchorMenuOpen}
          anchorMenuRef={anchorMenuRef}
          setSelectedStudy={setSelectedStudy}
          setStudyDialogOpen={setStudyDialogOpen}
        />
      </Suspense>
    </>
  )
}
