import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import IToolData, { getToolTypes } from 'types/IToolData'
import Checkbox from 'components/control/Checkbox'
import Select, { SelectMenuItemData } from 'components/control/Select'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import DialogComponent from 'components/dialog/DialogComponent'
import StudyRepository from 'repositories/StudyRepository'
import { toolMutate } from 'hooks/studyMutations'

type ToolDialogProps = {
  onClose: () => void
  open: boolean
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
}

function ToolDialog({ onClose, open, tool, mutateStudy }: ToolDialogProps) {
  const { t } = useLingui()
  const { setActionMenuContext } = useActionMenuContext()
  const [isLoading, setLoading] = useState(false)
  const [isTemplate, setIsTemplate] = useState(tool.is_template)
  const [toolType, setToolType] = useState(tool.tool_type)
  const [languageCode, setLanguageCode] = useState(tool.language_code)
  const dialogRef = useRef(null)
  const toolTypes = getToolTypes()

  const handleSaveDialog = () => {
    if (
      isTemplate !== tool.is_template ||
      languageCode !== tool.language_code ||
      toolType !== tool.tool_type
    ) {
      const toolToUpdate: IToolData = {
        ...tool,
        ...{
          is_template: isTemplate,
          tool_type: toolType,
          language_code: languageCode,
        },
      }
      setLoading(true)

      StudyRepository.updateTool(tool.id, toolToUpdate).then((data) => {
        mutateStudy(toolMutate(tool.station, tool.id, data), false)
          .then(() => {
            onClose()
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              tool: toolToUpdate,
            }))
          })
          .finally(() => setLoading(false))
      })
    } else {
      onClose()
    }
  }

  const handleCloseDialog = (): void => {
    setIsTemplate(tool.is_template)
    setLanguageCode(tool.language_code)
    setToolType(tool.tool_type)
    onClose()
  }

  const getToolTypeData = (): SelectMenuItemData[] => {
    const toolTypeData: SelectMenuItemData[] = []

    toolTypes.forEach((toolType) => {
      toolTypeData.push({ value: toolType.id, text: toolType.text })
    })

    toolTypeData.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))
    return toolTypeData
  }

  const getLanguageData = (): SelectMenuItemData[] => {
    const languageData: SelectMenuItemData[] = []
    // TODO:
    // A better design would be to loop through the locales and populate with
    // the list of supported languages.
    languageData.push({ value: 'en', text: t`English` })
    languageData.push({ value: 'fr', text: t`French` })
    languageData.push({ value: 'es', text: t`Spanish` })
    languageData.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))
    return languageData
  }

  return (
    <DialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 4, width: 'auto' }}
      isLoading={isLoading}
      dialogTitle={t`Configure tool`}
    >
      <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem' }}>
        {tool.title ? tool.title : 'default (empty) title'}
      </DialogContentText>
      <Box sx={{ mt: 4 }}>
        <Select
          label={t`Tool type`}
          onChange={(e: SelectChangeEvent<string>) => setToolType(e.target.value)}
          options={getToolTypeData()}
          sx={{ fontSize: '0.95rem' }}
          value={toolType}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Checkbox
          checked={isTemplate}
          color="primary"
          label={<Typography sx={{ fontSize: '0.95rem' }}>{t`This tool is a template`}</Typography>}
          labelPlacement="end"
          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            setIsTemplate(checked)
          }
        />
      </Box>
      <Box>
        <Select
          label={t`List language (for templates and AI personalities)`}
          onChange={(e: SelectChangeEvent<string>) => setLanguageCode(e.target.value)}
          options={getLanguageData()}
          sx={{ fontSize: '0.95rem', width: 400 }}
          value={languageCode}
        />
      </Box>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="24px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            disabled={isLoading}
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveDialog}
            disabled={isLoading}
            sx={{ width: 'auto' }}
          >
            {t`Save`}
          </Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  )
}

export default React.memo(ToolDialog)
