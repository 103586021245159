import { forwardRef, useState } from 'react'

import { useLingui } from '@lingui/react/macro'
import { KeyedMutator } from 'swr'
import { useFormContext } from 'react-hook-form'

import { Box, IconButton, Collapse, InputAdornment } from '@mui/material'
import {
  ExpandLessOutlined as ExpandLessIcon,
  ExpandMoreOutlined as ExpandMoreIcon,
  Folder as FolderIcon,
} from '@mui/icons-material'

import ActionMenu from 'components/action-menu/ActionMenu'
import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import { FormStudyDataHeader } from 'components/form/StudyDataFormHeader'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard } from 'contexts/ClipboardContext'

import headerStyle from 'shared/components/studyStyle'
import theme, { colors } from 'shared/theme'

import IStudyData from 'types/IStudyData'

import StudyBack from './StudyBack'

interface StudyHeaderProps {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
}

const StudyHeader = forwardRef<HTMLDivElement, StudyHeaderProps>((props, ref) => {
  const { t } = useLingui()
  const { study, mutateStudy, clipboard } = props

  const { control } = useFormContext<FormStudyDataHeader>()

  const { resetActionMenuContext } = useActionMenuContext()

  const [showStudyNote, setShowStudyNote] = useState(true)

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }

    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const config = {
      title: {
        ...theme.typography.h1,
        fontWeight: 400,
        color: colors.blue.A800,
      },
      note: {
        ...theme.typography.body1,
        fontWeight: 400,
        color: colors.gray.A600,
      },
    }

    const defaultStyle = {}
    const chosenStyle = config[name] || defaultStyle

    return {
      ...generalProps,
      style: {
        ...generalStyles,
        ...chosenStyle,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...chosenStyle,
        },
      },
    }
  }

  const handleStudyHeaderClick = () => {
    resetActionMenuContext()
  }

  return (
    <Box ref={ref} sx={headerStyle()}>
      <Box className="MuiBox-titleContainer">
        <Box display="flex" sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Box display="flex" alignItems="center" width="40px" margin="0 0 0 0">
            <StudyBack study={study} />
          </Box>

          <Box onClick={handleStudyHeaderClick} sx={{ width: '100%' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}
            >
              <Box display="flex">
                <FormTextFieldBlur
                  name="study.title"
                  control={control}
                  placeholder={t`New Study`}
                  slotProps={{
                    input: {
                      ...getInputProps('title'),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="16px"
                          >
                            <IconButton
                              onClick={() => setShowStudyNote((prev) => !prev)}
                              sx={{ color: colors.black.A500, padding: 0 }}
                              disableRipple
                            >
                              {showStudyNote ? (
                                <ExpandLessIcon sx={{ fontSize: '24px' }} />
                              ) : (
                                <ExpandMoreIcon sx={{ fontSize: '24px' }} />
                              )}
                            </IconButton>

                            {study.is_template && (
                              <FolderIcon sx={{ fontSize: '22px', color: colors.red.A500 }} />
                            )}
                          </Box>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Box>

              <Collapse in={showStudyNote} timeout="auto" unmountOnExit>
                <Box>
                  <FormTextFieldBlur
                    name="study.note"
                    control={control}
                    placeholder={t`Study description`}
                    multiline
                    slotProps={{
                      input: getInputProps('note'),
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Box>

      <ActionMenu study={study} mutateStudy={mutateStudy} clipboard={clipboard} />
    </Box>
  )
})

StudyHeader.displayName = 'StudyHeader'

export default StudyHeader
