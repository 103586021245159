import React, { useState } from 'react'
import { Box, Grid2, CircularProgress, Typography, Container } from '@mui/material'
import useStudyLists from 'hooks/useStudyLists'
import IStudyListData from 'types/IStudyListData'
import SideMenuDrawer from 'components/SideMenuDrawer'
import ProjectCard from 'pages/projects/ProjectCard'
import ProjectDialog from 'components/dialog/ProjectDialog'
import { colors } from 'shared/theme'
import AddImportButton from 'components/AddImportButton'
import useTeams from 'hooks/useTeams'
import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'
import { useLingui } from '@lingui/react/macro'

function Projects() {
  const { t } = useLingui()
  const { studyLists } = useStudyLists()
  const { teams } = useTeams()
  const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)
  const [selectedStudyList, setSelectedStudyList] = useState<IStudyListData | null>(null)

  const handleAddProject = () => {
    setSelectedStudyList(null)
    setProjectDialogOpen(true)
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ padding: { xs: '0 24px 0 24px', md: '0 24px 0 0' } }}
    >
      <Box display="flex">
        <SideMenuDrawer studyLists={studyLists?.data} teams={teams?.data} />

        <Box sx={sideMenuDrawerRightBoxStyle()}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            margin="0 0 24px 0"
            gap={{ xs: '24px', md: '0' }}
          >
            <Box flexGrow={1}>
              <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                {t`All projects`}
              </Typography>
            </Box>

            <AddImportButton addButtonText={t`Add new project`} handleAddClick={handleAddProject} />
          </Box>

          {studyLists?.data ? (
            <Box>
              <Grid2 container spacing={2}>
                {studyLists.data?.map((studyList: IStudyListData) => (
                  <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} key={`studyList-${studyList.id}`}>
                    <ProjectCard
                      studyList={studyList}
                      setSelectedStudyList={setSelectedStudyList}
                      setProjectDialogOpen={setProjectDialogOpen}
                    />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
              }}
            >
              <CircularProgress sx={{ p: 0.5 }} />
            </Box>
          )}

          <ProjectDialog
            project={selectedStudyList}
            teams={teams ? teams.data : null}
            open={projectDialogOpen}
            onModalClose={() => setProjectDialogOpen(false)}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(Projects)
