import React, { useRef, useState } from 'react'
import { Box, Button, DialogActions, SelectChangeEvent, Typography } from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import { MutatorCallback } from 'swr'
import IStudyData from 'types/IStudyData'
import Checkbox from 'components/control/Checkbox'
import DialogComponent from 'components/dialog//DialogComponent'
import { useErrorContext } from 'contexts/ErrorContext'
import { colors } from 'shared/theme'
import StudyRepository from 'repositories/StudyRepository'
import Select, { SelectMenuItemData } from '../control/Select'

type StudyConfigDialogProps = {
  onClose: () => void
  open: boolean
  study: IStudyData
  mutator: MutatorCallback
}

function StudyConfigDialog({ onClose, open, study, mutator }: StudyConfigDialogProps) {
  const { t } = useLingui()
  const { handleAsyncError } = useErrorContext()
  const [isHelper, setIsHelper] = useState(study.is_helper)
  const [languageCode, setLanguageCode] = useState(study.language_code)
  const [isTemplate, setIsTemplate] = useState(study.is_template)
  const [isLoading, setLoading] = useState(false)
  const dialogRef = useRef(null)

  const handleSaveDialog = () => {
    if (
      isHelper !== study.is_helper ||
      isTemplate !== study.is_template ||
      languageCode !== study.language_code
    ) {
      const studyToUpdate: IStudyData = {
        ...study,
        ...{ is_helper: isHelper, is_template: isTemplate, language_code: languageCode },
      }
      setLoading(true)
      StudyRepository.updateStudy(study.id, studyToUpdate)
        .then(() => {
          mutator()
          onClose()
        })
        .catch((err) => {
          handleAsyncError(err.message)
        })
        .finally(() => setLoading(false))
    } else {
      onClose()
    }
  }

  const handleCloseDialog = () => {
    setIsHelper(study.is_helper)
    setIsTemplate(study.is_template)
    setLanguageCode(study.language_code)
    onClose()
  }

  const getLanguageData = (): SelectMenuItemData[] => {
    const languageData: SelectMenuItemData[] = []
    // TODO:
    // A better design would be to loop through the locales and populate with
    // the list of supported languages.
    languageData.push({ value: 'en', text: t`English` })
    languageData.push({ value: 'fr', text: t`French` })
    languageData.push({ value: 'es', text: t`Spanish` })
    languageData.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))
    return languageData
  }

  return (
    <DialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={isLoading}
      dialogTitleNode={
        <Box padding="24px 24px 8px 24px">
          <Typography
            variant="h1"
            sx={{ color: colors.black.A600 }}
          >{t`Configure Study`}</Typography>
        </Box>
      }
    >
      <Typography variant="h5" color="textPrimary">
        {t`Select additional options available for this study`}
      </Typography>

      <Box margin="16px 0 0 0">
        <Checkbox
          checked={isTemplate}
          color="primary"
          label={
            <Typography
              variant="h5"
              sx={{ color: colors.black.A600 }}
            >{t`This study is a template`}</Typography>
          }
          labelPlacement="end"
          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            setIsTemplate(checked)
          }
          sx={{ py: 0 }}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Select
          label={t`Board language (for templates)`}
          onChange={(e: SelectChangeEvent<string>) => setLanguageCode(e.target.value)}
          options={getLanguageData()}
          sx={{ fontSize: '0.95rem' }}
          value={languageCode}
        />
      </Box>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="40px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            disabled={isLoading}
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveDialog}
            disabled={isLoading}
            sx={{ width: 'auto', minWidth: '80px' }}
          >
            {t`Save`}
          </Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  )
}

export default React.memo(StudyConfigDialog)
