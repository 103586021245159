import {
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  CardHeader,
  CardActions,
} from '@mui/material'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useLingui } from '@lingui/react/macro'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import IStudyListData from 'types/IStudyListData'
import { colors } from 'shared/theme'
import ProjectMoreActionMenu from 'pages/projects/ProjectMoreActionMenu'
import { useNavigate } from 'react-router-dom'
import paths from 'utils/paths'

type ProjectCardProps = {
  studyList: IStudyListData
  setSelectedStudyList: Dispatch<SetStateAction<IStudyListData | null>>
  setProjectDialogOpen: Dispatch<SetStateAction<boolean>>
  teamId?: string
}

const lineHeight = '1.5em'
const numberOfLines = 4
const maxHeight = `calc(${lineHeight} * ${numberOfLines})`

export default function ProjectCard({
  studyList,
  setSelectedStudyList,
  setProjectDialogOpen,
  teamId,
}: ProjectCardProps) {
  const { t } = useLingui()
  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  return (
    <>
      <Card elevation={1} sx={{ height: '100%', paddingTop: '8px' }}>
        <CardHeader
          action={
            <IconButton
              onClick={() => setAnchorMenuOpen(!anchorMenuOpen)}
              ref={anchorMenuRef}
              size="small"
              sx={{ color: colors.gray.A500 }}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
              {studyList.title && studyList.title !== '' ? studyList.title : t`New project`}
            </Typography>
          }
        />
        <CardContent sx={{ paddingTop: '16px' }}>
          <Typography
            component="p"
            whiteSpace="pre-wrap"
            sx={{
              lineHeight,
              maxHeight,
              overflow: 'hidden',
              display: '-webkit-box',
              textOverflow: 'ellipsis',
              margin: 0,
              height: maxHeight,
            }}
          >
            {studyList.note}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => navigate(paths.frontend.studyList(studyList.id))}
            variant="contained"
            type="button"
            sx={{ width: '100%' }}
          >
            {studyList.is_default ? t`Start here` : t`Enter project`}
          </Button>
        </CardActions>
      </Card>
      <ProjectMoreActionMenu
        studyList={studyList}
        anchorMenuOpen={anchorMenuOpen}
        setAnchorMenuOpen={setAnchorMenuOpen}
        anchorMenuRef={anchorMenuRef}
        setSelectedStudyList={setSelectedStudyList}
        setProjectDialogOpen={setProjectDialogOpen}
        teamId={teamId}
      />
    </>
  )
}
