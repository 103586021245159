import { Button, Typography, Alert, Box } from '@mui/material'
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import TextFieldComponent from 'components/control/TextFieldComponent'
import { FormContainer } from 'react-hook-form-mui'
import { useLingui } from '@lingui/react/macro'
import React, { useMemo } from 'react'
import { useSnackbar } from 'notistack'
import IStudyListData from 'types/IStudyListData'
import SelectComponent from 'components/control/SelectComponent'
import StudyListDataService from 'data-services/StudyListDataService'
import useStudyLists from 'hooks/useStudyLists'
import { AxiosResponse } from 'axios'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import useStudyList from 'hooks/useStudyList'
import paths from 'utils/paths'
import ITeamData from 'types/ITeamData'
import { useTeam } from 'hooks/useTeams'

type ProjectFormProps = {
  project: IStudyListData | null
  teams: ITeamData[] | null
  isLoading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  onModalClose: () => void
  currentTeam?: ITeamData
}

export default function ProjectForm(props: ProjectFormProps) {
  const { project, teams, isLoading, setLoading, onModalClose, currentTeam } = props
  const { t } = useLingui()
  const { mutateStudyLists } = useStudyLists()
  const { mutateStudyList } = useStudyList(project?.id)
  const { mutateTeam } = useTeam(currentTeam?.id)
  const { enqueueSnackbar } = useSnackbar()
  const navigate: NavigateFunction = useNavigate()

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IStudyListData>({
    defaultValues: project ? useMemo(() => project, [project]) : undefined,
  })
  const projectTitleWatch = useWatch({ control, name: 'title' })

  const handleAddProject = (data: IStudyListData) => StudyListDataService.addStudyList(data)

  const handleEditProject = (data: IStudyListData) =>
    StudyListDataService.updateStudyList(data.id, data)

  const handleProjectFormSubmit = (data: IStudyListData) => {
    setLoading(true)
    let responsePromise: Promise<AxiosResponse<IStudyListData>>
    if (project) responsePromise = handleEditProject(data)
    else {
      responsePromise = handleAddProject(data)
    }

    responsePromise
      .then((response: AxiosResponse<IStudyListData>) => {
        void mutateStudyLists()
        void mutateStudyList()
        void mutateTeam()
        onModalClose()

        if (project) {
          enqueueSnackbar(t`Project "${data.title}" successfully updated`, {
            variant: 'success',
          })
        } else {
          navigate(paths.frontend.studyList(response?.data?.id))
        }
      })
      .catch((err) => {
        if (err.response.status !== 404 && err.response.data) {
          Object.keys(err.response.data).forEach((key) => {
            setError(key as keyof IStudyListData, {
              message: err.response.data[key],
            })
          })
        } else {
          setError('root.serverError', {
            message: t`Unexpected result, please try again.`,
          })
        }
      })

    setLoading(false)
  }

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography color="textPrimary" variant="h1" fontWeight={400}>
        {project ? t`Edit project` : t`Add new project`}
      </Typography>

      <FormContainer
        onSuccess={
          handleSubmit((e) => handleProjectFormSubmit(e)) as unknown as SubmitHandler<FieldValues>
        }
      >
        {errors.root?.serverError && (
          <Box margin="0 0 15px 0">
            <Alert severity="error">{errors.root?.serverError.message}</Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap="24px">
          <Box>
            <TextFieldComponent
              control={control as never}
              name="title"
              type="text"
              label={t`Project name`}
              errors={errors}
              requiredMsg={t`Project title is required`}
              autoFocus
              sx={{ background: 'white', width: '100%' }}
            />
          </Box>

          {!project?.id && (
            <Box>
              <SelectComponent
                disabled={!!project}
                control={control as never}
                requiredMsg={t`Team is required`}
                name="team"
                label={t`Add to team`}
                errors={errors as never}
                options={teams ? (teams as []) : []}
                sx={{ background: 'white', width: '100%' }}
              />
            </Box>
          )}

          <Box>
            <TextFieldComponent
              control={control as never}
              name="note"
              type="text"
              label={t`Project description`}
              errors={errors}
              sx={{ background: 'white', width: '100%' }}
              multiline
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" margin="16px 0" gap="8px">
            <Button
              variant="containedWhite"
              type="button"
              disabled={isLoading}
              sx={{ width: 'auto' }}
              onClick={() => onModalClose()}
            >
              {t`Cancel`}
            </Button>

            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !projectTitleWatch}
              sx={{ width: 'auto' }}
            >
              {project ? t`Update` : t`Add project`}
            </Button>
          </Box>
        </Box>
      </FormContainer>
    </Box>
  )
}
