import React, { useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import { IClipboard, IClipboardItemState } from 'contexts/ClipboardContext'
import IToolData from 'types/IToolData'
import floatingActionMenuStyle from 'shared/components/floatingActionMenuStyle'
import IStationData from 'types/IStationData'
import Checkbox from 'components/control/Checkbox'

type FloatingActionMenuToolProps = {
  station?: IStationData
  tool?: IToolData
  clipboard: IClipboard
}

export default function FloatingActionMenuTool({
  station,
  tool,
  clipboard,
}: FloatingActionMenuToolProps) {
  const { t } = useLingui()
  const [actionButtonLoading] = useState(false)

  const cbToolState: IClipboardItemState<IToolData> | null = clipboard.tools.getItemState(tool?.id)
  const isCopiedToClipboard: boolean = cbToolState !== null && cbToolState.action === 'copy'
  const isCutToClipboard: boolean = cbToolState !== null && cbToolState.action === 'cut'

  const handleCopyCheckboxClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (tool) {
      if (!isCopiedToClipboard && !isCutToClipboard) {
        clipboard.tools.addItem('copy', tool)
      } else {
        clipboard.tools.removeItem(tool.id)
      }
    }
  }

  return (
    <>
      <Box position="relative">
        <Box sx={floatingActionMenuStyle(station?.is_pinned)}>
          {tool && (
            <Box display="flex" alignItems="flex-start" justifyContent="center">
              <Tooltip
                title={
                  isCopiedToClipboard || isCutToClipboard
                    ? t`Remove Tool from clipboard`
                    : t`Add Tool to clipboard`
                }
                disableInteractive
              >
                <Box sx={{ opacity: 0.4 }}>
                  <Checkbox
                    onClick={(e) => handleCopyCheckboxClick(e)}
                    checked={isCopiedToClipboard || isCutToClipboard}
                    color="primary"
                    sx={{ zIndex: '1' }}
                    disabled={actionButtonLoading}
                  />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
