import { memo, useState } from 'react'

import { Box, Grid2, CircularProgress } from '@mui/material'

import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import InfiniteScroll from 'react-infinite-scroll-component'

import orderingHelper from 'utils/orderingHelper'

import { KeyedMutator } from 'swr'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard } from 'contexts/ClipboardContext'
import { useErrorContext } from 'contexts/ErrorContext'
import { useHeaderHeight } from 'contexts/HeaderHeight'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import { bodyStyle } from 'shared/components/studyStyle'
import infiniteScrollStyle from 'shared/components/infiniteScrollStyle'
import StationDataService from 'data-services/StationDataService'

import ToolDataService from 'data-services/ToolDataService'
import TermDataService from 'data-services/TermDataService'
import StationDroppable from 'components/station/StationDroppable'
import DrawerComponent from 'components/DrawerComponent'
import useWindowDimensions from 'utils/useWindowDimensions'
import pinnedStationDrawerStyle from 'shared/components/pinnedStationDrawerStyle'

import IStationData from 'types/IStationData'
import IToolData from 'types/IToolData'

import IStudyData, { IStudyGridItemData } from 'types/IStudyData'
import ITermData, { ITermMoveData } from 'types/ITermData'

const pageSize = 150

interface StudyBodyProps {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  pinnedStations: IStationData[]
  clipboard: IClipboard
}

function StudyBody({ study, mutateStudy, pinnedStations, clipboard }: StudyBodyProps) {
  const [page, setPage] = useState(1)

  const { setActionMenuContext } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()
  const { height: topHeaderHeight } = useHeaderHeight()

  const { isDragging, setIsDragging, isPinnedStationDrawerOpen, setPinnedStationDrawerOpen } =
    useStudyStatesContext()

  const { height: screenHeight } = useWindowDimensions()

  const handleNext = () => {
    if (!isDragging) {
      setTimeout(() => {
        setPage(page + 1)
      }, 1000)
    }
  }

  const moveToolsFront = (
    stationPrevious: IStationData,
    stationDestination: IStationData,
    toolToMove: IToolData,
    row: number,
    column: number,
  ): IStudyData => {
    if (row > stationDestination.row_count || column > stationDestination.column_count) {
      return study
    }

    const toolsToUpdate = stationDestination.tools
      .filter((tool) => tool.row >= row && tool.column === column && tool.id !== toolToMove.id)
      .sort((a, b) => a.row - b.row)

    let currentRow = row
    let newRowMax = stationDestination.row_count

    const updatedTools = toolsToUpdate.map((tool) => {
      const updatedTool = { ...tool }
      if (updatedTool.row === currentRow) {
        updatedTool.row += 1
        currentRow += 1
        newRowMax = Math.max(newRowMax, updatedTool.row)
      }

      return updatedTool
    })

    const finalTools = stationDestination.tools.map((originalTool) => {
      const updatedTool = updatedTools.find((updatedTool) => updatedTool.id === originalTool.id)
      if (originalTool.id === toolToMove.id) {
        return { ...originalTool, row, column, station: stationDestination.id }
      }
      return updatedTool || originalTool
    })

    const isToolMovedIncluded = finalTools.some((tool) => tool.id === toolToMove.id)
    if (!isToolMovedIncluded) {
      const movedToolWithNewPosition = {
        ...toolToMove,
        row,
        column,
        station: stationDestination.id,
      }
      finalTools.push(movedToolWithNewPosition)
    }

    let updatedPreviousStationTools = []
    if (stationPrevious.id !== stationDestination.id) {
      updatedPreviousStationTools = stationPrevious.tools.filter(
        (tool) => tool.id !== toolToMove.id,
      )
    }

    return {
      ...study,
      stations: study.stations.map((station) => {
        if (station.id === stationDestination.id) {
          return {
            ...station,
            row_count: newRowMax,
            tools: finalTools,
          }
        }
        if (station.id === stationPrevious.id) {
          return {
            ...station,
            tools: updatedPreviousStationTools,
          }
        }
        return station
      }),
    }
  }

  const moveStationsFront = (
    stationToMove: IStationData,
    row: number,
    column: number,
  ): IStudyData => {
    if (row > study.row_count || column > study.column_count) {
      return study
    }

    const stationsToUpdate = study.stations
      .filter(
        (station) =>
          station.row >= row && station.column === column && station.id !== stationToMove.id,
      )
      .sort((a, b) => a.row - b.row)

    let currentRow = row
    let newRowMax = study.row_count
    const updatedStations = stationsToUpdate.map((station) => {
      const updatedStation = { ...station }
      if (updatedStation.row === currentRow) {
        updatedStation.row += 1
        currentRow += 1
        newRowMax = Math.max(newRowMax, updatedStation.row)
      }

      return updatedStation
    })

    const finalStations = study.stations.map((originalStation) => {
      const updatedStation = updatedStations.find((updated) => updated.id === originalStation.id)
      if (originalStation.id === stationToMove.id) {
        return { ...originalStation, row, column }
      }
      return updatedStation || originalStation
    })

    return {
      ...study,
      row_count: newRowMax,
      stations: finalStations,
    }
  }

  const moveTools = (toolId: string, data: IStudyGridItemData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      ToolDataService.moveToolGridItem(toolId, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const moveStations = (stationId: string, data: IStudyGridItemData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      StationDataService.moveStationGridItem(stationId, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const moveTerms = (originalTermId: string, data: ITermMoveData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      TermDataService.moveTerm(originalTermId, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false)
    const { destination, source, type } = result

    if (!destination || !source || !type) {
      return
    }

    if (destination.droppableId === source.droppableId && type !== 'term') {
      return
    }

    const originalKey = source.droppableId as string
    const destinationKey = destination.droppableId as string
    const { row: originalRow, column: originalColumn } =
      orderingHelper.getRowColumnByKey(originalKey)
    const { row: destinationRow, column: destinationColumn } =
      orderingHelper.getRowColumnByKey(destinationKey)

    if (type === 'station') {
      const station = orderingHelper.findStationByRowColumn(
        study.stations,
        originalRow,
        originalColumn,
      )
      void mutateStudy(moveStationsFront(station, destinationRow, destinationColumn), false)
      void moveStations(station.id, {
        row: destinationRow,
        column: destinationColumn,
      })
    }

    if (type === 'tool') {
      const originalStation = study.stations.find(
        (station) => station.id === originalKey.split('-tool')[0],
      )
      const destinationStation = study.stations.find(
        (station) => station.id === destinationKey.split('-tool')[0],
      )
      const originalTool = orderingHelper.findToolByRowColumn(
        originalStation,
        originalRow,
        originalColumn,
      )
      void mutateStudy(
        moveToolsFront(
          originalStation,
          destinationStation,
          originalTool,
          destinationRow,
          destinationColumn,
        ),
        false,
      )
      void moveTools(originalTool.id, {
        row: destinationRow,
        column: destinationColumn,
        station: destinationStation.id,
      })
    }

    if (type === 'term') {
      const originalStation = study.stations.find(
        (station) =>
          station.id ===
          originalKey.substring(
            originalKey.indexOf('station-') + 8,
            originalKey.lastIndexOf('-tool'),
          ),
      )
      const originalTool = originalStation?.tools.find(
        (tool) =>
          tool.id ===
          originalKey.substring(originalKey.indexOf('tool-') + 5, originalKey.lastIndexOf('-term')),
      )
      const destinationStation = study.stations.find(
        (station) =>
          station.id ===
          destinationKey.substring(
            destinationKey.indexOf('station-') + 8,
            destinationKey.lastIndexOf('-tool'),
          ),
      )
      const destinationTool = destinationStation?.tools.find(
        (tool) =>
          tool.id ===
          destinationKey.substring(
            destinationKey.indexOf('tool-') + 5,
            destinationKey.lastIndexOf('-term'),
          ),
      )

      const originalTerm = orderingHelper.findTerm(study, result.draggableId.split('term-')[1])
      if (originalTerm && originalTerm.value) {
        const newStudy = {
          ...study,
          stations: study.stations.map((station) => ({
            ...station, // spread the station properties
            tools: station.tools.map((tool) => {
              if (tool.id === destinationTool.id) {
                return {
                  ...tool,
                  terms: orderingHelper.orderTerms(
                    tool.terms,
                    originalTerm.value as ITermData,
                    destination.index + 1,
                  ),
                }
              }
              if (tool.id === originalTool.id) {
                return {
                  ...tool,
                  terms: [
                    ...tool.terms.slice(0, originalTerm.index),
                    ...tool.terms.slice(originalTerm.index + 1),
                  ],
                }
              }
              return tool
            }),
          })),
        }

        void mutateStudy(newStudy, false)
        void moveTerms(originalTerm.value.id, {
          destination_tool: destinationTool.id,
          destination_index: destination.index,
        })
      }
    }
  }

  const handleStudyBodyClick = () => {
    setActionMenuContext({
      stationGridItem: null,
      station: null,
      toolGridItem: null,
      tool: null,
      stationListId: null,
      termGridItem: null,
      term: null,
    })
  }

  const handleCloseDrawer = () => {
    setPinnedStationDrawerOpen(false)
  }

  return (
    <Box
      id="MuiStudyBody-root"
      sx={bodyStyle(isPinnedStationDrawerOpen, topHeaderHeight)}
      onClick={handleStudyBodyClick}
    >
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={() => setIsDragging(true)}>
        <Box
          display="flex"
          width={isPinnedStationDrawerOpen ? 'calc(100% - 16px)' : '100%'}
          margin={isPinnedStationDrawerOpen ? '12px 0 24px 16px' : '0'}
          gap={isPinnedStationDrawerOpen ? '24px' : '0'}
        >
          <Box
            id="MuiInfiniteScroll-root"
            sx={infiniteScrollStyle(isPinnedStationDrawerOpen, screenHeight, topHeaderHeight)}
          >
            <InfiniteScroll
              dataLength={page * pageSize}
              next={handleNext}
              hasMore={(study.row_count || 1) > page * pageSize && !isDragging}
              loader={
                <Box height="100px" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              }
              scrollThreshold={0.8}
              scrollableTarget={
                isPinnedStationDrawerOpen ? 'MuiInfiniteScroll-root' : 'MuiStudyBody-root'
              }
            >
              {[...Array(Math.min(study.row_count + 1 || 1, page * pageSize + 1))].map(
                (_, rowIndex) => (
                  <Grid2
                    key={`row-${rowIndex}`}
                    container
                    wrap="nowrap"
                    spacing={0}
                    alignItems="stretch"
                    sx={{ margin: rowIndex === 0 ? '12px 0 0 0' : '0' }}
                  >
                    {[...Array(study.column_count + 1 || 1)].map((_, columnIndex) => (
                      <StationDroppable
                        key={`row-${rowIndex}-column-${columnIndex}`}
                        study={study}
                        stationRow={rowIndex}
                        stationColumn={columnIndex}
                        mutateStudy={mutateStudy}
                        clipboard={clipboard}
                        viewType="grid"
                      />
                    ))}
                  </Grid2>
                ),
              )}
            </InfiniteScroll>
          </Box>

          {isPinnedStationDrawerOpen && (
            <Box
              sx={pinnedStationDrawerStyle(
                isPinnedStationDrawerOpen,
                screenHeight,
                topHeaderHeight,
              )}
            >
              <DrawerComponent
                className="MuiDrawer-pinnedStations"
                openDrawer={isPinnedStationDrawerOpen}
                anchor="right"
                width="100%"
                setOpenDrawer={handleCloseDrawer}
                showBackdrop={false}
              >
                {pinnedStations.map((station) => (
                  <Box key={station.id} display="flex" width="100%">
                    <StationDroppable
                      key={`row-${station.row}-column-${station.column}`}
                      study={study}
                      stationRow={station.row}
                      stationColumn={station.column}
                      mutateStudy={mutateStudy}
                      clipboard={clipboard}
                      viewType="pin"
                    />
                  </Box>
                ))}
              </DrawerComponent>
            </Box>
          )}
        </Box>
      </DragDropContext>
    </Box>
  )
}

export default memo(StudyBody)
