import {
  createContext,
  useState,
  useContext,
  useMemo,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

interface HeaderHeightContextType {
  height: number
  setHeight: Dispatch<SetStateAction<number>>
}

interface HeaderHeightContextProviderProps {
  children: ReactNode
}

const HeaderHeightContext = createContext<HeaderHeightContextType | undefined>(undefined)

export const useHeaderHeight = () => {
  const context = useContext(HeaderHeightContext)

  if (!context) {
    throw new Error('useHeaderHeightContext must be used within a HeaderHeightContextProvider')
  }

  return context
}

export function HeaderHeightContextProvider({ children }: HeaderHeightContextProviderProps) {
  const [height, setHeight] = useState(0)

  const value = useMemo(
    () => ({
      height,
      setHeight,
    }),
    [height],
  )

  return <HeaderHeightContext.Provider value={value}>{children}</HeaderHeightContext.Provider>
}
