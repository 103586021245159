import { AxiosResponse } from 'axios'
import axiosInstance, { setLocalToken, unsetLocalToken } from '../utils/axios'
import IUserData, { IAuthData } from '../types/IUserData'

const changePassword = (password: string): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.put<unknown, AxiosResponse<IUserData>>('auth/account-password-update/me/', {
    password,
  })

const confirmEmail = (key: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.post<unknown, AxiosResponse<unknown>>('auth/email-verification/confirm-email/', {
    key,
  })

const getCurrentUserInfo = (): Promise<AxiosResponse<IUserData | null>> =>
  axiosInstance.post<unknown, AxiosResponse<IUserData | null>>(
    'auth/account-update/get-current-user/',
  )

const getUserInfoFromEmailVerificationKey = (
  key: string,
): Promise<AxiosResponse<IUserData | null>> =>
  axiosInstance.post<unknown, AxiosResponse<IUserData | null>>(
    'auth/email-verification/get-user-from-key/',
    { key },
  )

const getUserInfoFromPasswordResetKey = (key: string): Promise<AxiosResponse<IUserData | null>> =>
  axiosInstance.post<unknown, AxiosResponse<IUserData | null>>(
    'auth/password-reset/get-user-from-key/',
    { key },
  )

const login = (email: string, password: string): Promise<boolean> => {
  const promise = new Promise<boolean>((resolve, reject) => {
    axiosInstance
      .post('auth/login/', { email, password })
      .then((response) => {
        const authData: IAuthData = response.data
        setLocalToken(authData)
        resolve(true)
      })
      .catch((err) => {
        reject(err)
      })
  })
  return promise
}

const logout = (): Promise<boolean> => {
  const promise = new Promise<boolean>((resolve) => {
    unsetLocalToken()
    resolve(true)
  })
  return promise
}

const register = (
  name: string,
  email: string,
  password: string,
): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.post<unknown, AxiosResponse<IUserData>>('auth/register/', { name, email, password })

const resendVerificationEmail = (email: string): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.post<unknown, AxiosResponse<IUserData>>('auth/email-verification/resend-email/', {
    email,
  })

const resetPassword = (key: string, password: string): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.put<unknown, AxiosResponse<IUserData>>('auth/password-reset/me/', { key, password })

const saveProfile = (name: string, email: string): Promise<AxiosResponse<IUserData>> =>
  axiosInstance.put<unknown, AxiosResponse<IUserData>>('auth/account-update/me/', { name, email })

const sendPasswordResetEmail = (email: string): Promise<AxiosResponse<boolean>> =>
  axiosInstance.post('auth/password-reset/send-email/', { email })

const AccountDataService = {
  changePassword,
  confirmEmail,
  getCurrentUserInfo,
  getUserInfoFromEmailVerificationKey,
  getUserInfoFromPasswordResetKey,
  login,
  logout,
  register,
  resendVerificationEmail,
  resetPassword,
  saveProfile,
  sendPasswordResetEmail,
}

export default AccountDataService
