import { Box, Typography, MenuItem, ListItemIcon, Divider } from '@mui/material'
import { Dispatch, RefObject, SetStateAction, useState } from 'react'
import { useLingui } from '@lingui/react/macro'
import {
  DeleteOutline,
  ShareOutlined,
  EditOutlined,
  ContentPasteGo,
  UploadFileOutlined,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import IStudyListData from 'types/IStudyListData'
import PopperComponent from 'components/PopperComponent'
import { colors } from 'shared/theme'
import ConfirmDialog from 'components/control/ConfirmDialog'
import StudyListDataService from 'data-services/StudyListDataService'
import useStudyLists from 'hooks/useStudyLists'
import { useNavigate } from 'react-router-dom'
import paths from 'utils/paths'

type ProjectMoreActionMenuProps = {
  studyList: IStudyListData
  anchorMenuOpen: boolean
  setAnchorMenuOpen: Dispatch<SetStateAction<boolean>>
  anchorMenuRef: RefObject<HTMLButtonElement>
  setSelectedStudyList?: Dispatch<SetStateAction<IStudyListData | null>>
  setProjectDialogOpen: Dispatch<SetStateAction<boolean>>
  redirectAfterDelete?: boolean
  teamId?: string
}

export default function ProjectMoreActionMenu(props: ProjectMoreActionMenuProps) {
  const {
    studyList,
    anchorMenuOpen,
    setAnchorMenuOpen,
    anchorMenuRef,
    setSelectedStudyList,
    setProjectDialogOpen,
    redirectAfterDelete,
    teamId,
  } = props
  const { t } = useLingui()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { mutateStudyLists } = useStudyLists()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleEditProjectClick = () => {
    if (setSelectedStudyList) setSelectedStudyList(studyList)

    setProjectDialogOpen(true)
    setAnchorMenuOpen(false)
  }

  const handleDeleteProjectClick = () => {
    setDeleteDialogOpen(true)
    setAnchorMenuOpen(false)
  }
  const handleDeleteProjectConfirmClick = () => {
    const project = studyList
    StudyListDataService.deleteStudyList(studyList.id)
      .then(() => {
        mutateStudyLists().then(() => {
          if (redirectAfterDelete) navigate(paths.frontend.home)
          enqueueSnackbar(
            `Project "${
              project.title && project.title !== '' ? project.title : t`New project`
            }" successfully deleted`,
            { variant: 'success' },
          )
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
      })
  }

  return (
    <Box>
      <PopperComponent
        anchorMenu={anchorMenuOpen}
        setAnchorMenu={setAnchorMenuOpen}
        anchorRef={anchorMenuRef}
        backgroundColor={colors.lightBlue.A400}
        sx={{ minWidth: '185px', zIndex: 1 }}
      >
        <Box sx={{ padding: '8px' }}>
          <MenuItem onClick={handleEditProjectClick} sx={{ padding: '8px' }}>
            <ListItemIcon>
              <EditOutlined sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography color="textPrimary">{t`Edit project`}</Typography>
          </MenuItem>

          <MenuItem
            onClick={() => navigate(paths.frontend.teamsSetting(teamId ?? studyList.team.id))}
            sx={{ padding: '8px' }}
          >
            <ListItemIcon>
              <ShareOutlined sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography color="textPrimary">{t`Share`}</Typography>
          </MenuItem>

          <MenuItem onClick={() => console.log('DUPLICATE')} sx={{ padding: '8px' }} disabled>
            <ListItemIcon>
              <ContentPasteGo sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography color="textPrimary">{t`Duplicate`}</Typography>
          </MenuItem>

          <MenuItem onClick={() => console.log('EXPORT')} sx={{ padding: '8px' }} disabled>
            <ListItemIcon>
              <UploadFileOutlined sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography color="textPrimary">{t`Export`}</Typography>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={handleDeleteProjectClick}
            className="MuiMenu-error"
            sx={{ padding: '8px' }}
          >
            <ListItemIcon>
              <DeleteOutline color="error" />
            </ListItemIcon>
            <Typography color="error">{t`Delete`}</Typography>
          </MenuItem>
        </Box>
      </PopperComponent>
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete project`}
        description={t`Are you sure you want to delete this project?`}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteProjectConfirmClick}
        title={t`Project`}
        confirmButtonVariant="containedRed"
        cancelButtonVariant="containedWhite"
      />
    </Box>
  )
}
