/* eslint-disable camelcase */

import yup from '../utils/yupLocale'
import IToolData, { ToolExportProps, ToolImportSchema } from './IToolData'

export default interface IStationData {
  id: string
  title: string
  subtitle: string
  subject: string
  note?: string
  tools: IToolData[]
  direction: 'row' | 'column'
  is_pinned: boolean
  is_template: boolean
  language_code: string
  row: number
  column: number
  row_count: number
  column_count: number
  study: string
  pin_order: number
}

export const StationExportProps: string[] = [
  'title',
  'subtitle',
  'subject',
  'note',
  'direction',
  'is_pinned',
  'is_template',
  'language_code',
  'row_count',
  'column_count',
  'row',
  'column',
  'pin_order',
  'tools',
  ...ToolExportProps,
]

export const StationImportSchema = yup
  .object()
  .shape({
    title: yup.string(),
    subtitle: yup.string(),
    subject: yup.string(),
    note: yup.string(),
    tools: yup.array().of(ToolImportSchema),
    is_pinned: yup.boolean(),
    isPinned: yup.boolean(),
    is_template: yup.boolean(),
    isTemplate: yup.boolean(),
    language_code: yup.string().nullable(),
    languageCode: yup.string().nullable(),
    row_count: yup.string().defined(),
    column_count: yup.string().defined(),
    row: yup.string().defined(),
    column: yup.string().defined(),
    pin_order: yup.string().defined(),
  })
  .test(
    'is_template',
    'is_template or isTemplate must be defined',
    (data) => data.is_template !== undefined || data.isTemplate !== undefined,
  )

export const serializeStation = (station: IStationData): string =>
  JSON.stringify(station, StationExportProps)
