import { useLingui } from '@lingui/react/macro'
import { Box } from '@mui/material'

import { useFormContext } from 'react-hook-form'

import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import FloatingActionMenuTool from 'components/floating-action-menu/FloatingActionMenuTool'
import { FormStudyData } from 'components/form/StudyDataFormBody'

import { IClipboard } from 'contexts/ClipboardContext'

import theme, { colors } from 'shared/theme'

import IStationData from 'types/IStationData'
import IToolData from 'types/IToolData'

type ToolHeaderProps = {
  tool: IToolData
  station: IStationData
  clipboard: IClipboard
}

function ToolHeader({ tool, station, clipboard }: ToolHeaderProps) {
  const { t } = useLingui()
  const { control } = useFormContext<FormStudyData>()

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }

    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const config = {
      title: {
        ...theme.typography.h5,
        fontWeight: 500,
        color: colors.black.A600,
      },
      note: {
        ...theme.typography.body1,
        fontWeight: 400,
        color: colors.gray.A600,
      },
    }

    const defaultStyle = {}
    const chosenStyle = config[name] || defaultStyle

    return {
      ...generalProps,
      style: {
        ...generalStyles,
        ...chosenStyle,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...chosenStyle,
        },
      },
    }
  }

  return (
    <div>
      <Box
        display="flex"
        width="100%"
        sx={{
          background: 'transparent',
        }}
      >
        <FormTextFieldBlur
          // @ts-ignore
          name={`tools[${tool.id}].title`}
          placeholder={tool.tool_type === 'ai' ? t`This is your helpful colleague.` : t`Tool title`}
          slotProps={{
            input: getInputProps('title'),
          }}
          multiline
          control={control}
        />

        <FloatingActionMenuTool station={station} tool={tool} clipboard={clipboard} />
      </Box>

      <Box
        display="flex"
        width="100%"
        sx={{
          background: 'transparent',
          padding: '0',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <FormTextFieldBlur
          // @ts-ignore
          name={`tools[${tool.id}].note`}
          placeholder={
            tool.tool_type === 'ai'
              ? t`Click on the button below to change its configuration`
              : t`Tool description`
          }
          slotProps={{
            input: getInputProps('note'),
          }}
          multiline
          control={control}
        />
      </Box>
    </div>
  )
}

export default ToolHeader
