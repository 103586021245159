import { memo } from 'react'

import { useLingui } from '@lingui/react/macro'

import { useFormContext } from 'react-hook-form'

import FormTextFieldBlur from 'components/control/FormTextFieldBlur'

import { FormStudyData } from 'components/form/StudyDataFormBody'

import theme, { colors } from 'shared/theme'

import ITermData from 'types/ITermData'

type TermInputProps = {
  term: ITermData
}

function TermInput({ term }: TermInputProps) {
  const { t } = useLingui()
  const { control } = useFormContext<FormStudyData>()

  const getInputProps = (name: string, endsWithColon: boolean) => {
    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const typographyStyle = endsWithColon ? theme.typography.h3 : theme.typography.h5

    return {
      disableUnderline: true,
      style: {
        ...generalStyles,
        ...typographyStyle,
        color: colors.black.A600,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...typographyStyle,
          color: colors.black.A600,
        },
      },
    }
  }

  return (
    <FormTextFieldBlur
      // @ts-ignore
      name={`terms[${term.id}].term`}
      placeholder={t`Term title`}
      slotProps={{
        input: getInputProps('term', term.term.endsWith(':')),
      }}
      multiline
      control={control}
    />
  )
}

export default memo(TermInput)
