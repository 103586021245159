import React, { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  MoreHoriz as MoreHorizIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
  ChecklistRtlOutlined as ChecklistRtlIcon,
} from '@mui/icons-material'
import { useLingui } from '@lingui/react/macro'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { colors } from 'shared/theme'
import { serializeTerm } from 'types/ITermData'
import { IClipboard } from 'contexts/ClipboardContext'

type ActionMenuTermRightProps = {
  clipboard: IClipboard
}

export default function ActionMenuTermRight({ clipboard }: ActionMenuTermRightProps) {
  const { t } = useLingui()
  const { actionMenuContext, isContextMenuLoading, setContextMenuLoading } = useActionMenuContext()
  const actionButtonMoreActionRef = useRef<HTMLButtonElement>(null)
  const [actionButtonMoreActionOpen, setActionButtonMoreActionOpen] = useState(false)

  const handleExportTermToFile = (): void => {
    setActionButtonMoreActionOpen(false)
  }

  const handleSelectAllTermsInTool = async () => {
    if (actionMenuContext.tool) {
      setContextMenuLoading(true)

      for (const toolTerm of actionMenuContext.tool.terms) {
        clipboard.terms.addItem('copy', toolTerm)
      }

      setContextMenuLoading(false)
    }
  }

  return (
    <Box width={{ xs: '100%', mobile: 'auto' }}>
      {actionMenuContext && actionMenuContext.term && (
        <Box
          display="flex"
          width="100%"
          gap={{ xs: '8px', mobile: '32px' }}
          sx={{ flexDirection: { xs: 'column', mobile: 'row' } }}
        >
          <ActionMenuButton
            handleActionMenuButtonClick={handleSelectAllTermsInTool}
            StartIcon={ChecklistRtlIcon}
            title={t`Select All Terms`}
          />

          <ActionMenuButton
            actionMenuButtonRef={actionButtonMoreActionRef}
            actionMenuButtonAnchorOpen={actionButtonMoreActionOpen}
            setActionMenuButtonAnchorOpen={setActionButtonMoreActionOpen}
            handleActionMenuButtonClick={() =>
              setActionButtonMoreActionOpen(!actionButtonMoreActionOpen)
            }
            StartIcon={MoreHorizIcon}
            title={t`More Actions`}
            isButtonDisabled={isContextMenuLoading}
            actionMenuItems={
              <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
                <Button
                  onClick={handleExportTermToFile}
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    serializeTerm(actionMenuContext.term),
                  )}`}
                  variant="text"
                  disableRipple
                  startIcon={<DriveFolderUploadIcon sx={{ color: colors.black.A500 }} />}
                  sx={{ width: '100%', padding: '8px 24px' }}
                  download={`STS-Term (${actionMenuContext.term.term}).json`}
                  component="a"
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {t`Export Term to File`}
                  </Typography>
                </Button>
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  )
}
