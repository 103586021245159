import React, { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  MoreHoriz as MoreHorizIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material'
import { useLingui } from '@lingui/react/macro'
import { colors } from 'shared/theme'
import IStudyData, { serializeStudy } from 'types/IStudyData'
import StudyConfigDialog from 'components/dialog/StudyConfigDialog'
import { KeyedMutator } from 'swr'

type ActionMenuStudyRightProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
}

export default function ActionMenuStudyRight({ study, mutateStudy }: ActionMenuStudyRightProps) {
  const { t } = useLingui()
  const actionButtonMoreActionRef = useRef<HTMLButtonElement>(null)
  const [actionButtonMoreActionOpen, setActionButtonMoreActionOpen] = useState(false)
  const [configDialogOpen, setConfigDialogOpen] = useState(false)

  const handleExportStudyToFile = (): void => {
    setActionButtonMoreActionOpen(false)
  }

  const handleOpenConfigDialog = (): void => {
    setConfigDialogOpen(true)
    setActionButtonMoreActionOpen(false)
  }

  const handleCloseConfigDialog = (): void => {
    setConfigDialogOpen(false)
  }

  return (
    <Box width={{ xs: '100%', mobile: 'auto' }}>
      <Box display="flex" width="100%" gap="32px">
        <ActionMenuButton
          actionMenuButtonRef={actionButtonMoreActionRef}
          actionMenuButtonAnchorOpen={actionButtonMoreActionOpen}
          setActionMenuButtonAnchorOpen={setActionButtonMoreActionOpen}
          handleActionMenuButtonClick={() =>
            setActionButtonMoreActionOpen(!actionButtonMoreActionOpen)
          }
          StartIcon={MoreHorizIcon}
          title={t`More Actions`}
          actionMenuItems={
            <Box
              display="flex"
              gap={{ xs: '8px', mobile: '32px' }}
              padding="0 16px"
              flexDirection="column"
            >
              <Button
                onClick={handleOpenConfigDialog}
                variant="text"
                type="button"
                disableRipple
                startIcon={<SettingsIcon sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Configure Study`}
                </Typography>
              </Button>
              <Button
                onClick={handleExportStudyToFile}
                href={`data:text/json;charset=utf-8,${encodeURIComponent(serializeStudy(study))}`}
                variant="text"
                disableRipple
                startIcon={<DriveFolderUploadIcon sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
                download={t`STS Board (${study?.title}).json`}
                component="a"
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Save Board`}
                </Typography>
              </Button>
            </Box>
          }
        />
      </Box>

      {study && (
        <StudyConfigDialog
          open={configDialogOpen}
          onClose={handleCloseConfigDialog}
          study={study}
          mutator={mutateStudy}
        />
      )}
    </Box>
  )
}
