import React, { useState } from 'react'
import { Box, Button, Collapse, Divider, Typography } from '@mui/material'
import sideMenuDrawerStyle from 'shared/components/sideMenuDrawerStyle'
import styled from '@emotion/styled'
import { useLingui } from '@lingui/react/macro'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { AddOutlined } from '@mui/icons-material'
import DrawerComponent from 'components/DrawerComponent'
import { useDrawerComponentContext } from 'contexts/DrawerComponentContext'
import { RotationalExpandMoreIcon } from 'components/control/RotationalIcons'
import theme, { colors } from 'shared/theme'
import paths from 'utils/paths'
import IStudyListData from 'types/IStudyListData'
import ITeamData from 'types/ITeamData'
import ProjectDialog from 'components/dialog/ProjectDialog'

type SideMenuDrawerProps = {
  studyLists?: IStudyListData[]
  teams?: ITeamData[]
}

function SideMenuDrawer({ studyLists, teams }: SideMenuDrawerProps): JSX.Element {
  const { t } = useLingui()
  const { drawerOpen, setDrawerOpen } = useDrawerComponentContext()
  const [projectExpanded, setProjectExpanded] = useState<boolean>(true)
  const [teamExpanded, setTeamExpanded] = useState<boolean>(true)
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)

  const DrawerComponentStyled = styled(DrawerComponent)`
    ${sideMenuDrawerStyle}
  `

  const isCurrentRoute = (key: string): boolean => {
    const { pathname } = location
    if (pathname) {
      return pathname.includes(key)
    }

    return false
  }

  return (
    <DrawerComponentStyled
      className="MuiDrawer-absolute"
      openDrawer={drawerOpen}
      setOpenDrawer={setDrawerOpen}
      width={{ xs: '100%', md: theme.sideMenuDesktopWidth }}
      showBackdrop={false}
    >
      <Box display="flex" flexDirection="column" margin="32px 24px 24px 24px" gap="16px">
        <Button
          onClick={() => setProjectExpanded(!projectExpanded)}
          variant="text"
          type="button"
          disableRipple
          sx={{ width: 'auto', padding: '8px 24px' }}
          endIcon={
            <RotationalExpandMoreIcon
              fontSize="small"
              aria-label={t`Show more`}
              degrees={projectExpanded ? 0 : -90}
              sx={{ color: colors.blue.A800 }}
            />
          }
        >
          <Typography fontWeight={500} color="textSecondary">
            {t`Projects`}
          </Typography>
        </Button>

        <Collapse in={projectExpanded} timeout="auto" unmountOnExit>
          <Box display="flex" flexDirection="column" gap="8px">
            {studyLists &&
              studyLists.map((studyList) => (
                <Button
                  key={studyList.id}
                  onClick={() => navigate(paths.frontend.studyList(studyList.id))}
                  variant="text"
                  type="button"
                  disableRipple
                  sx={{ width: 'auto', padding: '8px 24px' }}
                >
                  <Typography
                    color="textSecondary"
                    textAlign="left"
                    noWrap
                    className={isCurrentRoute(studyList.id) ? 'MuiTypography-active' : ''}
                  >
                    {studyList.title && studyList.title !== '' ? studyList.title : t`New project`}
                  </Typography>
                </Button>
              ))}
          </Box>
          <Button
            onClick={() => setProjectDialogOpen(true)}
            variant="text"
            type="button"
            disableRipple
            sx={{ width: 'auto', padding: '8px 24px', margin: '16px 0 0 0' }}
            startIcon={<AddOutlined fontSize="small" sx={{ color: colors.blue.A800 }} />}
          >
            <Typography fontWeight={500} color="textSecondary">
              {t`Create new project`}
            </Typography>
          </Button>
        </Collapse>
      </Box>
      <Divider sx={{ borderColor: colors.lightBlue.A600 }} />
      <Box display="flex" flexDirection="column" margin="24px" gap="16px">
        <Button
          onClick={() => setTeamExpanded(!teamExpanded)}
          variant="text"
          type="button"
          disableRipple
          sx={{ width: 'auto', padding: '8px 24px' }}
          endIcon={
            <RotationalExpandMoreIcon
              fontSize="small"
              aria-label={t`Show more`}
              degrees={teamExpanded ? 0 : -90}
              sx={{ color: colors.blue.A800 }}
            />
          }
        >
          <Typography fontWeight={500} color="textSecondary">
            {t`Teams`}
          </Typography>{' '}
        </Button>

        <Collapse in={teamExpanded} timeout="auto" unmountOnExit>
          <Box display="flex" flexDirection="column" gap="8px">
            {teams &&
              teams.map((team) => (
                <Button
                  key={team.id}
                  onClick={() => navigate(paths.frontend.teams(team.id))}
                  variant="text"
                  type="button"
                  disableRipple
                  sx={{ width: 'auto', padding: '8px 24px' }}
                >
                  <Typography
                    color="textSecondary"
                    textAlign="left"
                    noWrap
                    className={isCurrentRoute(team.id) ? 'MuiTypography-active' : ''}
                  >
                    {team.name && team.name !== '' ? team.name : t`New project`}
                  </Typography>
                </Button>
              ))}
          </Box>
          <Button
            onClick={() => navigate(paths.frontend.teamsAdd)}
            variant="text"
            type="button"
            disableRipple
            sx={{ width: 'auto', padding: '8px 24px', margin: '16px 0 0 0' }}
            startIcon={<AddOutlined fontSize="small" sx={{ color: colors.blue.A800 }} />}
          >
            <Typography fontWeight={500} color="textSecondary">
              {t`Create new team`}
            </Typography>
          </Button>
        </Collapse>
        {projectDialogOpen && teams && (
          <ProjectDialog
            project={null}
            teams={teams}
            open={projectDialogOpen}
            onModalClose={() => setProjectDialogOpen(false)}
          />
        )}
      </Box>
    </DrawerComponentStyled>
  )
}

export default React.memo(SideMenuDrawer)
