/* eslint-disable camelcase */

import yup from 'utils/yupLocale'
import IStationData, { StationExportProps, StationImportSchema } from 'types/IStationData'
import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'

export default interface IStudyData {
  id: string
  title: string
  subtitle: string
  background: string
  note: string
  is_template: boolean
  is_helper: boolean
  language_code: string
  stations: IStationData[]
  study_list: string
  contributingUser_ids: string[]
  row_count: number
  column_count: number
}

export interface IStudyGridItemData {
  selected_row?: number
  selected_column?: number
  direction?: string
  target?: string
  row?: number
  column?: number
  station?: string
  tool?: string
}

export interface IStudyImportData {
  row?: number
  column?: number
  template_id?: string
  station?: IStationData
  tool?: IToolData
  term?: ITermData
  title?: string
  note?: string
  language_code?: string
}

type IPasteDataAction = {
  action: 'cut' | 'copy'
  station?: string
  tool?: string
  term?: string
}

export interface IPasteDataInterface {
  row?: number
  column?: number
  term?: string
  actions: IPasteDataAction[]
}

export const StudyExportProps: string[] = [
  'title',
  'subtitle',
  'background',
  'note',
  'is_template',
  'language_code',
  'is_helper',
  'stations',
  'row_count',
  'column_count',
  'contributingUser_ids',
  ...StationExportProps,
]

export const StudyImportSchema = yup
  .object()
  .shape({
    title: yup.string(),
    subtitle: yup.string(),
    background: yup.string(),
    note: yup.string(),
    is_template: yup.boolean(),
    isTemplate: yup.boolean(),
    languageCode: yup.string().nullable(),
    language_code: yup.string().nullable(),
    is_helper: yup.boolean(),
    isHelper: yup.boolean(),
    stations: yup.array().of(StationImportSchema),
    row_count: yup.string().defined(),
    column_count: yup.string().defined(),
  })
  .test(
    'is_template',
    'is_template or isTemplate must be defined',
    (data) => data.is_template !== undefined || data.isTemplate !== undefined,
  )

export const serializeStudy = (study: IStudyData): string => JSON.stringify(study, StudyExportProps)
