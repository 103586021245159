import { Box, Grid2 } from '@mui/material'
import { useState } from 'react'
import IStudyListData from 'types/IStudyListData'
import ProjectDialog from 'components/dialog/ProjectDialog'
import ITeamData from 'types/ITeamData'
import ProjectCard from 'pages/projects/ProjectCard'

type TeamViewProps = {
  projects: IStudyListData[]
  teams: ITeamData[]
  team: ITeamData
}

export default function TeamView(props: TeamViewProps) {
  const { projects, teams, team } = props
  const [selectedStudyList, setSelectedStudyList] = useState<IStudyListData | null>(null)
  const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)

  return (
    <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} margin="48px 0 0 0">
      <Box>
        <Grid2 container spacing={2}>
          {projects?.map((studyList: IStudyListData) => (
            <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} key={`studyList-${studyList.id}`}>
              <ProjectCard
                studyList={studyList}
                setSelectedStudyList={setSelectedStudyList}
                setProjectDialogOpen={setProjectDialogOpen}
                teamId={team.id}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>

      {teams && (
        <ProjectDialog
          project={selectedStudyList}
          teams={teams}
          open={projectDialogOpen}
          onModalClose={() => setProjectDialogOpen(false)}
          currentTeam={team}
        />
      )}
    </Grid2>
  )
}
