import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { useLingui } from '@lingui/react/macro'

import { styled } from '@mui/material/styles'
import { Box, Button, Divider, IconButton, Popover, Tooltip, Typography } from '@mui/material'
import { Close as CloseIcon, UploadFile as UploadFileIcon } from '@mui/icons-material'

const classes = {
  root: 'FileUpload-root',
  onDragOver: 'FileUpload-onDragOver',
}

const StyledBox = styled(Box)({
  [`& .${classes.root}`]: {
    cursor: 'pointer',
    display: 'flex',
    '&:hover .MuiTypography-root, &:hover svg, &:hover img': {
      opacity: 0.4,
    },
    '& .MuiTypography-root, & svg, & img': {
      opacity: 1,
    },
    textAlign: 'center',
  },
  [`& .${classes.onDragOver}`]: {
    '& img': {
      opacity: 0.3,
    },
    '& .MuiTypography-root, & svg': {
      opacity: 0.4,
    },
  },
})

// Inspired by https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx:0-4974

export type FileUploadProps = {
  accept?: string
  dropLabel?: string
  height?: string
  hoverLabel?: string
  onUpload: (file: File, setErrorList: Dispatch<SetStateAction<string[]>>) => void
  width?: string
}

function FileUpload({
  accept = 'application/JSON',
  dropLabel = 'Drop file here',
  height = '100px',
  hoverLabel = 'Click or drag to upload file',
  onUpload,
  width = '400px',
}: FileUploadProps) {
  const { t } = useLingui()
  const isInitialMessageRender = useRef(true)
  const [labelText, setLabelText] = useState(hoverLabel)
  const [errorList, setErrorList] = useState<string[]>([])
  const [isDragOver, setIsDragOver] = useState(false)
  const [detailsAnchorEl, setDetailsAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (isInitialMessageRender.current) {
      isInitialMessageRender.current = false
    } else {
      const noErrorLabel = labelText === dropLabel ? dropLabel : hoverLabel
      setLabelText(errorList.length === 0 ? noErrorLabel : hoverLabel)
    }
  }, [errorList])

  const stopDefaults = (e: DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const dragEvents = {
    onDragEnter: (e: DragEvent) => {
      stopDefaults(e)
      setIsDragOver(true)
      setLabelText(dropLabel)
    },
    onDragLeave: (e: DragEvent) => {
      stopDefaults(e)
      setIsDragOver(false)
      setLabelText(hoverLabel)
    },
    onDragOver: stopDefaults,
    onDrop: (e: DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setIsDragOver(false)
      if (e.dataTransfer.files && e.dataTransfer.files?.length > 0) {
        setErrorList([])
        onUpload(e.dataTransfer.files[0], setErrorList)
      }
    },
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target?.files?.length > 0) {
      setErrorList([])
      onUpload(e.target.files[0], setErrorList)
    }
  }

  const handleDetailsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setDetailsAnchorEl(event.currentTarget)
  }

  const handleDetailsClose = () => {
    setDetailsAnchorEl(null)
  }

  const open = Boolean(detailsAnchorEl)
  const id = open ? 'details--popover' : undefined

  const renderErrorContent = () => {
    if (errorList.length === 1) {
      return (
        <StyledBox>
          <Typography sx={{ color: 'error.main' }}>
            {errorList[0]}
            <br />
          </Typography>
        </StyledBox>
      )
    }
    if (errorList.length > 1) {
      const errors = [...errorList]
      errors.splice(0, 1)

      return (
        <Box>
          <Typography sx={{ color: 'error.main' }}>{errorList[0]}</Typography>
          <Box sx={{ mb: 1, mt: 2 }}>
            <Button
              aria-describedby={id}
              onClick={handleDetailsClick}
              size="small"
              variant="outlined"
            >
              {t`Show error details`}
            </Button>
          </Box>
          <Popover
            anchorEl={detailsAnchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handleDetailsClose}
            id={id}
            open={open}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Box sx={{ minWidth: '300px' }}>
              <Box display="flex" sx={{ px: 2, py: 1 }}>
                <Box flexGrow={1}>{t`Error Details`}</Box>
                <Tooltip title={t`Close`} disableInteractive>
                  <IconButton
                    onClick={handleDetailsClose}
                    sx={{ color: 'text.secondary', mr: 0, p: 0 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider />
              <Box sx={{ p: 2, textAlign: 'center' }}>
                {errors.map((error: string) => (
                  <Typography key={error} sx={{ color: 'error.main' }}>
                    {error}
                    <br />
                  </Typography>
                ))}
              </Box>
            </Box>
          </Popover>
        </Box>
      )
    }
    return <Box />
  }

  return (
    <Box>
      <Box>
        <input
          accept={accept}
          id="file-upload"
          onChange={handleChange}
          style={{ display: 'none' }}
          type="file"
        />
        <label
          className={clsx(classes.root, isDragOver && classes.onDragOver)}
          htmlFor="file-upload"
          {...dragEvents}
        >
          <Box height={height} sx={{ pointerEvents: 'none' }} width={width}>
            <Box
              height={height}
              sx={{
                alignItems: 'center',
                color: 'primary.main',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'absolute',
              }}
              width={width}
            >
              <UploadFileIcon fontSize="large" />
              <Typography variant="h5" margin="16px 0 0 0">
                {labelText}
              </Typography>
            </Box>
          </Box>
        </label>
      </Box>
      {renderErrorContent()}
    </Box>
  )
}

export default FileUpload
