import { memo, useEffect, useMemo } from 'react'

import { Box, Container } from '@mui/material'

import { useForm } from 'react-hook-form'
import { useComponentSize } from 'react-use-size'

import StudyDataFormBody, { FormStudyData } from 'components/form/StudyDataFormBody'
import StudyDataFormHeader, { FormStudyDataHeader } from 'components/form/StudyDataFormHeader'

import { ClipboardState, IClipboard } from 'contexts/ClipboardContext'
import { useHeaderHeight } from 'contexts/HeaderHeight'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import studyToFormBody from 'helpers/studyToFormBody'
import studyToFormHeader from 'helpers/studyToFormHeader'

import theme from 'shared/theme'

import IStudyData from 'types/IStudyData'

import { KeyedMutator } from 'swr'

import useRefreshTimer from 'hooks/useRefreshTimer'
import DrawerOpeningControl from './DrawerOpeningControl'
import Reorder from './Reorder'
import StudyBody from './StudyBody'
import StudyHeader from './StudyHeader'

interface StudyContentProps {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
}

function StudyContent({ study, mutateStudy }: StudyContentProps) {
  const { setHeight } = useHeaderHeight()
  const { setPasteMenuCount } = useStudyStatesContext()

  const { resetTimer } = useRefreshTimer(mutateStudy)

  const { ref: headerRef, height: topHeaderHeight } = useComponentSize()

  const studyFormHeader = useForm<FormStudyDataHeader>({
    mode: 'onBlur',
    defaultValues: studyToFormHeader(study),
  })

  const studyForm = useForm<FormStudyData>({
    mode: 'onBlur',
    defaultValues: studyToFormBody(study),
  })

  const clipboard: IClipboard = ClipboardState()

  const pinnedStations = useMemo(
    () =>
      (study?.stations?.filter((station) => station?.is_pinned) ?? []).sort(
        (a, b) => a.pin_order - b.pin_order,
      ),
    [study?.stations],
  )

  useEffect(() => {
    if (topHeaderHeight !== 0) {
      setHeight(topHeaderHeight)
    }
  }, [topHeaderHeight])

  useEffect(() => {
    setPasteMenuCount(clipboard.totalItemCount)
  }, [clipboard.totalItemCount])

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ margin: `${theme.appBarHeight} 0 0 0`, height: 'calc(100vh  - 64px)' }}
    >
      <Box height="100%">
        <Box display="flex" flexDirection="column" height="100%">
          <StudyDataFormHeader study={study} studyForm={studyFormHeader} resetTimer={resetTimer}>
            <StudyHeader
              ref={headerRef}
              study={study}
              mutateStudy={mutateStudy}
              clipboard={clipboard}
            />
          </StudyDataFormHeader>

          <Reorder study={study} mutateStudy={mutateStudy} pinnedStations={pinnedStations} />

          <DrawerOpeningControl pinnedStations={pinnedStations} />

          <StudyDataFormBody study={study} studyForm={studyForm} resetTimer={resetTimer}>
            <StudyBody
              study={study}
              mutateStudy={mutateStudy}
              pinnedStations={pinnedStations}
              clipboard={clipboard}
            />
          </StudyDataFormBody>
        </Box>
      </Box>
    </Container>
  )
}

export default memo(StudyContent)
